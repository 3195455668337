<template lang="pug">
.border-top-h3.accordion(
  :class="{ 'angle-close': show && !embedded, 'angle-open': !show && !embedded, 'embedded-accordion': embedded }"
  ref="accordion"
)
  .h3-elem.mb-0(@click.self="toggle") {{ title }}
    DeviceSelector(
      v-if="addDeviceSelector"
      :float="embedded ? 'right' : null"
      :hasViewText="embedded ? true : false"
    )
    slot(name="tooltip")
  transition(name="expand")
    .expand-transition(v-if="embedded || show" :class="{ 'overflow-hidden': isOverflow }")
      slot
</template>
<script>
  import accordionMixin from '@/editor/mixins/sidebar/accordion';
  import DeviceSelector from './components/DeviceSelector.vue';

  export default {
    components: { DeviceSelector },
    mixins: [accordionMixin],
    props: {
      isOverflow: {
        type: Boolean,
        default: true,
      },
      title: {
        type: String,
      },
      opened: {
        type: Boolean,
      },
      embedded: {
        type: Boolean,
      },
      addDeviceSelector: {
        type: Boolean,
      },
      canRemainOpen: {
        type: Boolean,
        default: false,
      },
      leftSidebar: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        show: false,
      };
    },
    watch: {
      opened() {
        // this.show = this.opened
      },
    },

    beforeDestroy() {
      if (!this.embedded) {
        this.$bus.$off('closeAccordion', this.close);
      }
    },

    mounted() {
      if (!this.embedded) {
        // console.log('@reg accordion')
        this.$bus.$emit('registerAccordion', this);
        this.$bus.$on('closeAccordion', this.close);
      }
    },

    methods: {
      toggle() {
        if (this.embedded) {
          return;
        }

        if (this.show) {
          this.close();
          return;
        }
        // console.trace('@@@click')
        this.$bus.$emit('toggleAccordion', { id: this._uid, canRemainOpen: this.canRemainOpen });
      },

      scrollIntoView() {
        const sidebarPane = document.querySelector('.sidebar-inner-box');
        const accordion = this.$refs.accordion;

        if (!accordion) {
          return;
        }

        const accordionPos = accordion.getBoundingClientRect().top;

        let bottomPos;

        const copyElementIdEle = document.querySelector('.copy-element-id');
        if (copyElementIdEle) {
          bottomPos = copyElementIdEle.getBoundingClientRect().top;
        } else {
          bottomPos = sidebarPane.getBoundingClientRect().height;
        }

        if (bottomPos - accordionPos < 200) {
          this.$nextTick(() => {
            sidebarPane.scrollTo(0, accordion.offsetTop - 50);
          });
        }
      },

      open() {
        // console.log('@open', this._uid)
        this.show = true;
        this.scrollIntoView();
      },
    },
  };
</script>

<style lang="sass">
  .embedded-accordion
    &.border-top-h3
      border-top: none
      &:last-child
        border: none

    .h3-elem
      padding-top: 0
      padding-bottom: 0.5rem
      font-size: 0.8125rem
      font-weight: bold
      position: relative
      cursor: initial
      color: #313233

      &:hover
        background: initial

      &:after
        content: ''
        border-bottom: 2px solid #edeff1
        width: calc(100% - 3rem)
        height: 2px
        position: absolute
        bottom: 0
        left: 1.5rem
        right: 0
</style>
