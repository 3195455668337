<template lang="pug">
.pane
  sidebar-title
    span {{ $t('editDivider') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="style")
      template(#tabs)
        sidebar-tab-item#style(:name="$t('sidebarSettings.tabs.name.style')")
        sidebar-tab-item#advanced(:name="$t('sidebarSettings.tabs.name.advanced')")
      template(#content)
        sidebar-tab-predefined-style(property="selectedElement" component="divider" :mobile="true")
          custom-theme-style
          sidebar-accordion(:title="$t('display')")
            .mb-2
              align-select(
                label="align"
                property="$device.alignSelf"
                :options="textJustify.map(({ key }) => key)"
                :editMobile="true"
              )
            om-range-input(label="width" property="width" :min="10" :max="800" :step="1")
            om-range-input(label="height" property="border.width" :min="1" :max="40" :step="1")
            om-dropdown-input(label="type" property="border.type" :items="type")
            .mb-2
              om-color-input(
                label="color"
                property="desktop.border.color"
                typeOfComponent="divider"
              )
          om-shadow(property="desktop.shadow.color" typeOfComponent="divider")
        advanced-tab(slot="advancedTabContent" :spacingProps="spacingProps")
</template>
<script>
  import { mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';

  export default {
    components: {
      AlignSelect: () => import('@/editor/components/sidebar/components/AlignSelect.vue'),
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    mixins: [itemMixin],
    data() {
      return {
        show: true,
        type: [
          { key: 'line', value: 'solid' },
          { key: 'dashed', value: 'dashed' },
          { key: 'dotted', value: 'dotted' },
        ],
        textJustify: [
          { key: 'left', value: 'flex-start' },
          { key: 'center', value: 'center' },
          { key: 'right', value: 'flex-end' },
        ],
      };
    },
    computed: {
      ...mapState(['selectedElement']),
      spacingProps() {
        return {
          showPadding: false,
          typeOfComponent: 'divider',
        };
      },
    },
  };
</script>
