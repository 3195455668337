<template lang="pug">
.w-100(:class="classes")
  downgrade-info-bar
  .d-flex.justify-content-end.justify-content-md-start.pt-2.pb-5(v-if="showPeriodSelect")
    .d-inline-flex.align-items-center.brand-settings-toggle(
      @click="$emit('setPeriod', selectedPeriod === 12 ? 1 : 12)"
    )
      div(:class="{ selected: selectedPeriod === 12 }")
        span {{ $t('plan.yearly') }}
      div(:class="{ selected: selectedPeriod === 1 }")
        span {{ $t('plan.monthly') }}

  template(v-if="couponDetails && couponDetails.hasValidCoupon")
    .brand-pricing-table-coupon-details-wrapper
      .brand-pricing-table-coupon-details
        span {{ couponDetails.couponDescription }}
        om-tooltip.info-tooltip(
          v-if="couponDetails.couponValidityPeriod"
          theme="dark"
          placement="top-start"
          color="#1A7457"
        )
          span {{ $t('couponDetails.validityPeriod') }}:<br>{{ couponDetails.couponValidityPeriod.start }} - {{ couponDetails.couponValidityPeriod.end }}

  .d-flex.flex-column.flex-md-row.align-items-stretch.justify-content-between.brand-pricing-table.mb-5.mt-5(
    :class="{ 'brand-pricing-table-modal': isUsedInModal }"
  )
    template(v-for="(plan, index) in planListToShow")
      .brand-pricing-table-item.d-flex.flex-column.w-100.replative(
        :key="index"
        v-if="plan.name !== 'MASTER' && (!isUsedInModal || (isUsedInModal && plan.name.toUpperCase() !== 'FREE'))"
        :class="{ 'brand-pricing-table-item-recommended order-0 order-md-1': isRecommended(plan.name), 'order-1': !isRecommended(plan.name), 'brand-pricing-table-item-notsufficient': isPlanDowngrade(plan.name) && plan.name !== getCurrentPlan }"
      )
        .brand-pricing-table-item-notsufficient-pane.d-flex.justify-content-center.align-items-center.font-size-1--25.py-2(
          v-if="getRecommendedPlan() && isPlanDowngrade(plan.name) && plan.name !== getCurrentPlan"
        )
          span.mr-1 {{ $t('notSufficient') }}
          popper(trigger="hover" :options="{ placement: 'left' }")
            .popper.brand-pricing-table-item-popper.brand-pricing-table-item-popper-small.w-20.p-3
              span(
                v-html="$t(remainingDays < 1 ? 'planMisc.notSufficientDescriptionPassed' : 'planMisc.notSufficientDescription', { remainingDays })"
              )
            span(slot="reference")
              i.fas.fa-info-circle.ml-2.align-items-center
        .brand-pricing-table-item-recommended-pane.d-flex.justify-content-center.align-items-center.font-size-1--25.py-2(
          v-if="isRecommended(plan.name)"
        )
          | {{ $t('recommended') }}
          popper(trigger="hover" :options="{ placement: 'left' }")
            .popper.brand-pricing-table-item-popper.w-20.p-3
              img.w-10(:src="require('@/components/Elements/Monks/svgs/point.svg')")
              h3 {{ $t('recommendedPlan') }}
              span(v-html="displayRecommendationText()")
            div(slot="reference")
              i.fas.fa-info-circle.ml-2.align-items-center
        .brand-pricing-table-item-name.text-center.font-weight-bold.font-size-1--25.p-4(
          :style="{ color: getFontColor(plan) }"
        ) {{ plan.displayName }}
          .alt-text.mt-1 {{ $t(`altTitles.${plan.name}`) }}
        .brand-pricing-table-item-price.text-center.py-3.px-2
          div(v-if="['FREE', 'MASTER'].includes(plan.name)")
            span.brand-pricing-table-item-price-value.font-size-2.font-weight-bold {{ getPlanPriceString(plan) }}
          div(v-else)
            .brand-pricing-table-item-discounted-price(v-if="hasDiscountForPlan(plan)")
              span.font-weight-bold {{ getPlanOriginalPriceString(plan) }}
              span &nbsp; / {{ $t(selectedPeriodLanguageKey) }}
            span.brand-pricing-table-item-price-value.font-size-2.font-weight-bold {{ getPlanPriceString(plan) }}
            span.brand-pricing-table-item-price-label.ml-2.font-size-0--875 / {{ $t(selectedPeriodLanguageKey) }}
            .brand-pricing-table-item-annual-price(v-if="getCalculatedMonthlyPrice(plan)") {{ getCalculatedMonthlyPrice(plan) }} / {{ $t('month') }}

        .brand-pricing-table-item-divider
        .brand-pricing-table-item-body.h-100.d-flex.flex-column.py-3(v-if="plan.details")
          .mb-3.brand-pricing-table-item-features.font-size-0--875
            .font-weight-bold(v-if="plan.details.pageViews !== $t('contactUs')")
              fa-icon.mr-2(variant="fa-check")
              | {{ plan.details.pageViews }} {{ $t('planDetailTitles.pageViews') }}*
            .font-weight-bold(v-else)
              fa-icon.mr-2(variant="fa-check")
              | {{ plan.details.pageViews }}
            div
              fa-icon.mr-2(variant="fa-check")
              | {{ typeof plan.details.sites !== 'string' ? $t('planMisc.max') : '' }} {{ plan.details.sites }} {{ $t('planMisc.domain') }}
              popper(trigger="hover")
                .popper.brand-pricing-table-item-popper.brand-pricing-table-item-popper-small.w-20.p-3
                  span {{ $t('planMisc.sitesDescription') }}
                span(slot="reference")
                  i.fas.fa-info-circle.ml-1.align-items-center
            div
              fa-icon.mr-2(variant="fa-check")
              | {{ $t('planDetailTitles.allFeatures') }}
            div
              fa-icon.mr-2(variant="fa-check")
              | {{ $t('planDetailTitles.unlimitedCampaigns') }}
            .feature-unbranded(:class="{ 'not-available': plan.details.unbranded !== 'yes' }")
              fa-icon.mr-2(:variant="plan.details.unbranded === 'yes' ? 'fa-check' : 'fa-times'")
              | {{ $t('planDetailTitles.unbranded') }}
            div(v-if="plan.details.prioritySupport === 'yes'")
              fa-icon.mr-2(variant="fa-check")
              | {{ $t('planDetailTitles.prioritySupport') }}

          .mt-auto.d-inline-flex.flex-column.align-items-center
            template(v-if="!canCalculateCorrectUpgradePrice")

            template(
              v-else-if="plan.name === getCurrentPlan && !isChangingPeriod && needsShopifyPlanUpdateWithNewPrice"
            )
              .cursor-pointer.brand-btn.brand-btn-primary.brand-btn-sm(
                @click="$emit('shopifyNextBillingUpdate', plan.name, 'recurring_update', 'plan-table')"
              )
                span {{ $t('newCampaignFlow.needsPlanUpdate.withNewPrice') }}
            template(
              v-else-if="plan.name === getCurrentPlan && !isChangingPeriod && needsOverchargeUpgrade"
            )
              .cursor-pointer.brand-btn.brand-btn-primary.brand-btn-sm(
                @click="$emit('sendShopifyOrder', plan.name, 'usage_update')"
              )
                span {{ $t('newCampaignFlow.upGradeWithOvercharge') }}
            template(v-else)
              template(v-if="isChangingPeriod && !['MASTER', 'FREE'].includes(plan.name)")
                om-button(:primary="true" @click="$emit('setPlan', plan.name)")
                  span {{ $t('newCampaignFlow.choosePlan') }}
              template(v-else)
                .brand-pricing-table-item-current-plan.font-weight-bold(
                  v-if="plan.name === getCurrentPlan"
                  :class="{ 'px-0': $i18n.locale === 'hu' }"
                  :style="{ color: getFontColor(plan) }"
                ) {{ $t('upgradePlanPage.currentPlan').replace(':', '') }}

                om-button(
                  :primary="!isPlanDowngrade(plan.name)"
                  :secondary="isPlanDowngrade(plan.name)"
                  v-if="showPlanSelectButton(plan.name)"
                  @click="$emit('setPlan', plan.name)"
                )
                  span(v-if="isPlanSameAsCurrent(plan.name)") {{ $t('newCampaignFlow.selectCurrent') }}
                  span(v-else-if="isPlanDowngrade(plan.name)") {{ $t('newCampaignFlow.select') }}
                  span(v-else-if="needsOverchargeUpgrade") {{ $t('newCampaignFlow.upGradeAndActivateOvercharge') }}
                  span(v-else) {{ $t('newCampaignFlow.upGrade') }}

  .row.mt-4(style="justify-content: center")
    template(v-if="!canCalculateCorrectUpgradePrice")
      .mb-6 {{ $t('plan.masterEnterprise.contact') }}
        a.brand-link(href="mailto:support@optimonk.com") {{ $t('plan.masterEnterprise.support') }}

  master-contact-us-modal
  .brand-pricing-table-upgrade-info(v-if="hasActiveSubscription") {{ `${$t('planUpgradeInfoBox.title')} ${$t('planUpgradeInfoBox.description')}` }}
  master-plan-info-box(@action="contactUs")
</template>

<script>
  import moment from 'moment';
  import { createNamespacedHelpers, mapGetters, mapState } from 'vuex';
  import { isNewPaymentEnabled } from '@/utils/features';
  import DowngradeInfoBar from '@/components/DowngradeInfoBar';

  const { mapMutations: paymentMutations, mapGetters: paymentGetters } =
    createNamespacedHelpers('payment');

  export default {
    components: {
      DowngradeInfoBar,
      MasterContactUsModal: () => import('@/components/Modals/MasterContactUs'),
      MasterPlanInfoBox: () => import('@/components/Payment/MasterPlanInfoBox.vue'),
    },

    props: {
      plans: { type: Array, required: true },
      triggers: {
        type: Array,
        default: () => {
          return [];
        },
      },
      activeColumn: { type: String, default: '' },
      selectedPeriod: { type: Number, default: 1 },
      showPeriodSelect: { type: Boolean, default: true },
      canCalculateCorrectUpgradePrice: { type: Boolean, required: true },
      couponDetails: { type: Object, default: null },
      needsOverchargeUpgrade: { type: Boolean, default: false },
    },

    computed: {
      ...mapGetters(['currentPlan', 'currentPeriod', 'hasActiveSubscription']),
      ...paymentGetters([
        'needsOverchargeUpgrade',
        'needToDisplayPriceIncreaseInfo',
        'needsShopifyPlanUpdateWithNewPrice',
        'isInactive',
      ]),
      ...mapState(['account']),
      ...paymentGetters([
        'isPlanSameAsCurrent',
        'isPlanExpired',
        'isBraintreePayment',
        'isShopifyPayment',
        'getPlans',
        'hasFuturePackage',
        'getFuturePackage',
      ]),

      classes() {
        return {
          'mt-10': this.needsOverchargeUpgrade && !this.showPeriodSelect,
          'mt-5': !this.needsOverchargeUpgrade && !this.showPeriodSelect,
        };
      },
      planListToShow() {
        if (this.hasFuturePackage) {
          const found = this.plans.find((plan) => plan.name === this.getFuturePackage);
          return found ? [found] : [];
        }
        return this.plans;
      },

      selectedPeriodKey() {
        let period = 'monthly';
        if (this.selectedPeriod === 12) {
          period = 'annual';
        } else if (this.selectedPeriod === 3) {
          period = 'quarterly';
        }
        return period;
      },

      selectedPeriodLanguageKey() {
        let period = 'month';
        if (this.selectedPeriod === 12) {
          period = 'annual';
        } else if (this.selectedPeriod === 3) {
          period = 'quarter';
        }
        return period;
      },

      getCurrentPlan() {
        return this.currentPlan.toUpperCase();
      },

      getCurrentPageViews() {
        return this.account.limits.pageViews;
      },

      isUsedInModal() {
        // Probably this will be a wrong indicator in the future
        return this.triggers.length;
      },

      isUsedInPlanSettings() {
        return !this.triggers.length;
      },
      cycle() {
        return this.selectedPeriod === 1 ? 'month' : 'year';
      },

      remainingDays() {
        const date = new Date(this.calculateRemainingPageviews(this.getCurrentPlan));
        if (date < 1) {
          return 0;
        }

        return moment(date).diff(moment(new Date()), 'days');
      },
      isChangingPeriod() {
        return this.currentPeriod !== this.selectedPeriod;
      },
    },

    methods: {
      ...mapGetters(['getRecommendedPlan', 'getRecommendedPlanRunOuts']),
      ...paymentMutations(['setPeriod']),
      contactUs() {
        this.$modal.show('master-contact-us');
      },
      showPlanSelectButton(planName) {
        if (planName === 'MASTER') {
          return false;
        }

        const usedInModalAndDowngrade = !!this.isUsedInModal && this.isPlanDowngrade(planName);

        if (usedInModalAndDowngrade) {
          return false;
        }

        const planSameAsCurrent = this.isPlanSameAsCurrent(planName);

        if (planSameAsCurrent === false) {
          return true;
        }

        if (planName === 'FREE' && planSameAsCurrent) {
          return false;
        }

        // Braintree reactivation or manual pay after recurring problem
        if (this.isBraintreePayment && planSameAsCurrent && this.isPlanExpired) {
          return true;
        }

        // Shopify reactivation after Shopify pay has got inactive but recurring haven't put it to FREE
        // (nonfreemium suspended or freemium frozen account)
        if (this.isShopifyPayment && planSameAsCurrent && this.isPlanExpired && this.isInactive) {
          return true;
        }

        return false;
      },
      calculateRemainingPageviews(currentPlan) {
        let getDate = null;

        if (!this.getRecommendedPlanRunOuts().length) {
          return getDate;
        }

        this.getRecommendedPlanRunOuts().forEach((eachPlanRunOut) =>
          eachPlanRunOut.forEach((item) => {
            if (item === currentPlan) getDate = eachPlanRunOut[1];
          }),
        );

        return getDate;
      },
      daysFromMonthRound() {
        const billing = this.account.billing;
        const startOfMonth = moment().startOf('month');
        const dateExpires = moment(billing.dateExpires).subtract(14, 'days');

        let currentMonth = startOfMonth.get('month');
        if (moment().date() < dateExpires.date()) {
          currentMonth--;
        }

        // Simulate that the dateExpires is in the current month
        const arrangedExpirationDate = dateExpires
          .year(startOfMonth.get('year'))
          .month(currentMonth);

        const baseDate = isNewPaymentEnabled(this.account.features)
          ? arrangedExpirationDate
          : startOfMonth;

        return Math.abs(moment().diff(baseDate, 'days'));
      },
      displayRecommendationText() {
        const lastDays = this.daysFromMonthRound();
        const pageViews = this.getCurrentPageViews;

        if (this.remainingDays < 1) {
          return this.$t('planRecommendationItems.datePassed', {
            spentDays: lastDays,
            numPageviews: pageViews,
          });
        }
        return this.$t('planRecommendationItems.text', {
          spentDays: lastDays,
          numPageviews: pageViews,
          remainingDays: this.remainingDays,
        });
      },
      isPlanDowngrade(planName) {
        planName = planName.toUpperCase();
        const minimumPlanIndex = this.plans.findIndex((plan) => {
          return plan.name === planName;
        });
        const desiredPlanIndex = this.plans.findIndex((plan) => {
          return plan.name === this.getCurrentPlan;
        });
        return minimumPlanIndex <= desiredPlanIndex;
      },
      isRecommended(planName) {
        return planName === this.getRecommendedPlan() && !this.isPlanDowngrade(planName);
      },
      isPlanUpgrade(planName) {
        planName = planName.toUpperCase();
        const minimumPlanIndex = this.plans.findIndex((plan) => {
          return plan.name === this.activeColumn;
        });
        const desiredPlanIndex = this.plans.findIndex((plan) => {
          return plan.name === planName;
        });

        return minimumPlanIndex <= desiredPlanIndex;
      },
      getPrice(planName) {
        const cycle = this.selectedPeriod === 1 ? 'monthly' : 'annual';
        const plan = this.getPlans.find(({ name }) => name === planName);
        return plan.price[cycle];
      },
      getFontColor(plan) {
        return plan.fontColor;
      },
      getPlanPriceString(plan) {
        return plan.price[this.selectedPeriodKey];
      },
      hasDiscountForPlan(plan) {
        return plan.hasDiscount && plan.hasDiscount[this.selectedPeriodKey];
      },
      getPlanOriginalPriceString(plan) {
        if (!plan.originalPrice || !plan.originalPrice[this.selectedPeriodKey]) return null;
        return plan.originalPrice[this.selectedPeriodKey];
      },
      getCalculatedMonthlyPrice(plan) {
        if (this.selectedPeriod !== 12) return null;
        return plan.calculatedPrice?.monthlyPriceWithAnnual ?? null;
      },
    },
  };
</script>

<style lang="sass" scoped>
  .mt-10
    margin-top: 2.5rem
  .feature-unbranded
    &.not-available > div
      width: .875rem
      text-align: center
      i.fa
        font-size: 1.25em !important
</style>
