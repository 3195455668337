<template lang="pug">
portal(to="root")
  om-modal.lead-sync-modal(
    :name="name"
    :width="420"
    color="light"
    :scrollable="true"
    :clickToClose="true"
    @beforeOpen="beforeOpen"
    @beforeClose="beforeClose"
  )
    template(slot="modal-header")
      .brand-modal-action-icon
        close-icon.cursor-pointer(
          @click.native="$modal.hide(name)"
          :width="14"
          :height="14"
          color="#C2C2C2"
        )
    template(slot="modal-body")
      .container.d-flex.justify-content-center.flex-column.align-items-center
        om-heading.heading(h3) {{ $t('failedIntegration.leadSyncModal.title') }}
        span.sub-title.text-center {{ $t(`failedIntegration.leadSyncModal.${type}.subTitle`, { leadsCnt: totalLeads }) }}
        om-period-select#syncDateRange(
          v-if="isPeriodSelectType"
          v-model="dateRange"
          :options="dateRangeOptions"
          :minDate="minimumSelectableDate"
          :maxDate="maximumSelectableDate"
          @input="setDateRange"
          :locale="$i18n.locale"
          size="medium"
        )
        template(v-if="loading")
          skeleton-loader.w-100(:rows="1" :bottomLine="false")
        template(v-else)
          span.sub-title.text-center(
            v-if="(isPeriodSelectType && dateRange) || !isPeriodSelectType"
          ) {{ $t(`failedIntegration.leadSyncModal.${type}.resyncLeads`, { totalLeads, remainingLeads }) }}
    template(slot="modal-footer")
      om-button.ml-auto.mr-auto(
        primary
        :disabled="periodSelectButtonDisabled"
        @click="handleClick"
      ) {{ $t(`failedIntegration.leadSyncModal.${type}.ctaText`) }}
</template>
<script>
  import moment from 'moment';
  import dateRange from '@/mixins/dateRange';
  import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';

  export default {
    components: {
      SkeletonLoader,
    },
    mixins: [dateRange],
    data() {
      const dateRangeDateFormat = 'YYYY-MM-DD HH:mm:ss';
      const today = moment.utc().endOf('day').format(dateRangeDateFormat);

      return {
        name: 'lead-sync-modal',
        type: 'period-select',
        isNew: false,
        loading: false,
        failedIntegrationResyncService: null,
        dateRange: null,
        campaignId: null,
        newIntegrationData: null,
        totalLeads: 0,
        remainingLeads: 0,
        dateRangeDateFormat,
        dateRangeOptions: [
          {
            value: this.$t('dateRange.yesterday'),
            key: 'yesterday',
            interval: {
              from: moment.utc().subtract(1, 'days').startOf('day').format(dateRangeDateFormat),
              to: moment.utc().subtract(1, 'days').endOf('day').format(dateRangeDateFormat),
            },
          },
          {
            value: this.$t('dateRange.last7days'),
            key: 'past7days',
            interval: {
              from: moment.utc().subtract(6, 'days').startOf('day').format(dateRangeDateFormat),
              to: today,
            },
          },
          {
            value: this.$t('dateRange.last30days'),
            key: 'last30days',
            interval: {
              from: moment.utc().subtract(30, 'days').startOf('day').format(dateRangeDateFormat),
              to: today,
            },
          },
          {
            value: this.$t('dateRange.lastMonth'),
            key: 'lastMonth',
            interval: {
              from: moment.utc().subtract(1, 'month').startOf('month').format(dateRangeDateFormat),
              to: moment.utc().subtract(1, 'month').endOf('month').format(dateRangeDateFormat),
            },
          },
          {
            value: this.$t('dateRange.last1Year'),
            key: 'last1Year',
            interval: {
              from: moment.utc().subtract(1, 'year').startOf('day').format(dateRangeDateFormat),
              to: today,
            },
          },
        ],
      };
    },
    computed: {
      periodSelectButtonDisabled() {
        return (
          this.isPeriodSelectType && (!this.dateRange || this.loading || this.remainingLeads <= 0)
        );
      },
      isPeriodSelectType() {
        return this.type === 'period-select';
      },
      minimumSelectableDate() {
        return moment.utc().subtract(3, 'months').format('YYYY-MM-DD');
      },
    },
    methods: {
      beforeClose() {
        this.dateRange = null;
        this.campaignId = null;
        this.newIntegrationData = null;
        this.totalLeads = null;
        this.remainingLeads = null;
      },
      async beforeOpen(event) {
        const { type, isNew, totalLeads, campaignId, serviceInstance, newIntegrationData } =
          event.params;

        this.type = type;
        this.isNew = isNew;
        this.totalLeads = totalLeads;
        this.campaignId = campaignId;
        this.failedIntegrationResyncService = serviceInstance;

        if (newIntegrationData) {
          this.newIntegrationData = newIntegrationData;
        }
      },
      setLoading(value) {
        this.loading = value;
      },
      async setDateRange(event) {
        this.setLoading(true);
        const { interval, key } = event;
        if (key === 'allTime') {
          this.dateRange = { interval };
        } else {
          this.dateRange.interval.from = moment(interval.from).format(this.dateRangeDateFormat);
          let to = moment(interval.to);
          if (this.dateRange.key === 'custom') {
            to = to.endOf('day');
          }
          this.dateRange.interval.to = to.format(this.dateRangeDateFormat);
        }

        this.failedIntegrationResyncService.updateSessionWithDate({ date: this.dateRange });

        const remainingLeads = await this.failedIntegrationResyncService.fetchLeadsCount(
          this.dateRange.interval,
        );

        this.remainingLeads = remainingLeads;

        this.failedIntegrationResyncService.updateSessionWithErrorCount(remainingLeads);

        this.setLoading(false);
      },
      handlePeriodSelectClick() {
        const { integrationId } = this.failedIntegrationResyncService.getResyncDataFromSession();
        this.$router.push({
          name: 'campaign_settings',
          params: {
            userId: this.$route.params.userId,
            id: this.campaignId,
            resync: {
              integrationId,
              isNew: this.isNew,
              failedIntegrationResyncService: this.failedIntegrationResyncService,
            },
          },
          query: { activeBox: 'integration' },
        });
      },
      handleSynchronizingClick() {
        this.failedIntegrationResyncService.integrationSetupDone(this.newIntegrationData);
      },
      handleClick() {
        if (this.isPeriodSelectType) {
          this.handlePeriodSelectClick();
        } else {
          this.handleSynchronizingClick();
        }

        this.$modal.hide(this.name);
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'
  .lead-sync-modal
    ::v-deep .brand-modal-body
      padding-top: unset
      padding-bottom: 0.625rem
      .container
        gap: 12px
        .heading
          color: $om-gray-700
        .sub-title
          color: $om-gray-700
        .select-period-select-syncDateRange
          min-width: 21.25rem
        .skeleton-row
          padding: unset
</style>
