<template lang="pug">
div
  sidebar-title(:selectedRowColumns="selectedRowColumns")
    span {{ $t('editCol', { column: columnText(selectedColIndex) }) }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="style")
      template(#tabs)
        sidebar-tab-item#style(:name="$t('sidebarSettings.tabs.name.style')")
        sidebar-tab-item#advanced(:name="$t('sidebarSettings.tabs.name.advanced')")
      template(#content)
        sidebar-tab-predefined-style(property="selectedElement" component="column" :mobile="true")
          template(#before)
            custom-theme-style
            sidebar-accordion(v-if="!hasWheel" :title="$t('display')" :addDeviceSelector="true")
              transition(name="expand")
                .expand-transition.mt-4
                  om-dropdown-input(
                    v-if="isNano"
                    label="contentAlignment"
                    property="selectedColumn.desktop.contentAlignment"
                    :items="contentAlignment"
                    :i18n="false"
                  )
                  om-dropdown-input(
                    v-else-if="isFullscreen && mobilePreview && !isTeaserPage"
                    label="verticalAlignment"
                    property="selectedColumn.mobile.alignContent"
                    :items="alignContent"
                    :defaultOption="false"
                  )
                  om-dropdown-input(
                    v-else-if="mobilePreview"
                    label="verticalAlignment"
                    property="selectedColumn.mobile.verticalAlign"
                    :items="verticalAlignment"
                  )
                  om-dropdown-input(
                    v-else
                    label="verticalAlignment"
                    property="selectedColumn.desktop.verticalAlign"
                    :items="verticalAlignment"
                  )
        advanced-tab(slot="advancedTabContent" :spacingProps="spacingProps")
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import columnMixin from '@/editor/mixins/column';
  import itemMixin from '@/editor/mixins/item';

  export default {
    components: {
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },

    mixins: [columnMixin, itemMixin],

    data() {
      return {
        selectedColIndex: 0,
        selectedRowColumns: [],
        contentAlignment: [
          { key: 'Left', value: 'left' },
          { key: 'Center', value: 'center' },
          { key: 'Right', value: 'right' },
          { key: 'Space between', value: 'space-between' },
        ],
        verticalAlignment: [
          { key: 'top', value: 'flex-start' },
          { key: 'center', value: 'center' },
          { key: 'bottom', value: 'flex-end' },
        ],
        alignContent: [
          { key: 'Space evenly', value: 'normal' },
          { key: 'top', value: 'flex-start' },
          { key: 'center', value: 'center' },
          { key: 'bottom', value: 'flex-end' },
        ],
      };
    },

    computed: {
      ...mapGetters([
        'hasAccountFeature',
        'isNano',
        'isEmbedded',
        'nestedAccess',
        'isFullscreen',
        'isTeaserPage',
      ]),
      ...mapState([
        'selectedColumn',
        'selectedRow',
        'selectedElement',
        'mobilePreview',
        'hasWheel',
      ]),
      isHeightResizerEnabled() {
        return this.mobilePreview;
      },
      spacingProps() {
        return {
          showPadding: true,
          typeOfComponent: 'col',
        };
      },
    },

    created() {
      if (window.om.template) {
        this.selectedRowColumns = window.om.template.elements.filter(
          (e) => e.type === 'OmCol' && e.rowId === this.selectedColumn.rowId,
        );
        this.selectedColIndex = this.selectedRowColumns.findIndex(
          (c) => c.uid === this.selectedColumn.uid,
        );
      }
    },
  };
</script>
