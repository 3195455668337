<template lang="pug">
.align-select.sidebar-input-wrapper(:class="wrapperClasses" style="padding-bottom: 0px")
  .align-select-label.d-flex
    label(v-if="label") {{ $te(label) ? $t(label) : label }}
    device-selector(v-if="editMobile && !small" :hasViewText="false")
  .align-select-options(
    :class="{ 'align-select-options-text': isTypeText, 'd-flex': inline || isTypeText }"
  )
  template(v-if="small")
    template(v-for="option in options")
      om-button.justify-content-start.font-weight-normal(
        :small="!isTypeText"
        ghost
        :iconOnly="isTypeText"
        :primary="isSelected(option)"
        @click="setPropertyTo(option)"
        :class="{ selected: isSelected(option) }"
      )
        span(v-if="showOption") {{ $t(option) }}
        template(#icon)
          component(:is="getIconFrom(option)" size="1.715em")
  .blocks(v-else)
    .small-block(
      v-for="option in options"
      @click="setPropertyTo(option)"
      :class="{ 'active-block': isSelected(option), 'medium-block': options.length < 3 }"
    )
      .small-block-icon
        component(:is="getIconFrom(option)" size="1.25rem")
      .small-block-title(v-if="!isTypeText")
        span {{ $t(option) }}
</template>
<script>
  import { mapState } from 'vuex';
  import {
    UilHorizontalAlignLeft,
    UilHorizontalAlignCenter,
    UilHorizontalAlignRight,
    UilVerticalAlignBottom,
    UilVerticalAlignCenter,
    UilVerticalAlignTop,
    UilAlignLeft,
    UilAlignCenter,
    UilAlignRight,
  } from '@iconscout/vue-unicons';
  import itemMixin from '@/editor/mixins/item';
  import DeviceSelector from '@/editor/components/sidebar/components/DeviceSelector.vue';
  import { COLORING_TYPES } from '@/utils/color-components/helper';

  const OPT_TOP = 'top';
  const OPT_BOTTOM = 'bottom';
  const OPT_CENTER = 'center';
  const OPT_LEFT = 'left';
  const OPT_RIGHT = 'right';
  const VERTICAL_OPTIONS = [OPT_TOP, OPT_BOTTOM];
  const CSS_FLEX_START = 'flex-start';
  const CSS_FLEX_END = 'flex-end';
  const CSS_FLEX_MAP = {
    [OPT_TOP]: CSS_FLEX_START,
    [OPT_LEFT]: CSS_FLEX_START,
    [OPT_CENTER]: OPT_CENTER,
    [OPT_BOTTOM]: CSS_FLEX_END,
    [OPT_RIGHT]: CSS_FLEX_END,
  };

  const VALID_OPTS = [OPT_TOP, OPT_BOTTOM, OPT_LEFT, OPT_RIGHT, OPT_CENTER];
  const ucFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const TYPE_BLOCK = 'block';
  const TYPE_TEXT = 'text';
  const VALID_TYPES = [TYPE_BLOCK, TYPE_TEXT];

  export default {
    components: {
      DeviceSelector,
      UilHorizontalAlignLeft,
      UilHorizontalAlignCenter,
      UilHorizontalAlignRight,
      UilVerticalAlignBottom,
      UilVerticalAlignCenter,
      UilVerticalAlignTop,
      UilAlignLeft,
      UilAlignCenter,
      UilAlignRight,
    },
    mixins: [itemMixin],
    props: {
      label: {
        type: String,
        default: null,
      },
      property: {
        type: String,
        default: '',
      },
      options: {
        type: Array,
        requried: true,
        validator: (values) => values.reduce((acc, curr) => acc && VALID_OPTS.includes(curr), true),
      },
      vertical: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'block',
        validator: (value) => VALID_TYPES.includes(value),
      },
      inline: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      showOption: {
        type: Boolean,
        default: false,
      },
      colorInstance: {
        type: Object,
        default() {
          return null;
        },
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState(['mobilePreview']),
      currentlySet() {
        if (
          this.colorInstance &&
          this.colorInstance.getColoringType() === COLORING_TYPES.IMAGE &&
          this.label === 'horizontal'
        ) {
          return this.colorInstance.getImageHorizontalAlign();
        }
        if (
          this.colorInstance &&
          this.colorInstance.getColoringType() === COLORING_TYPES.IMAGE &&
          this.label === 'vertical'
        ) {
          return this.colorInstance.getImageVerticalAlign();
        }

        const getter = this.realProperty.includes('globalStyle') ? 'getValueOf' : 'smartGetValueOf';
        const value = this[getter](this.realProperty);

        return value;
      },
      isFlexProperty() {
        return /(justify|alignSelf|alignItems)/i.test(this.property);
      },
      isMobile() {
        return this.property.includes('mobile');
      },
      realProperty() {
        if (!this.editMobile) {
          return this.property.replace('mobile', 'desktop');
        }
        return this.property.replace('$device', this.editedDeviceType);
      },
      isTypeText() {
        return this.type === TYPE_TEXT;
      },
      wrapperClasses() {
        const wrappedClasses = this.isTypeText
          ? ['align-items-center', 'align-select-text']
          : ['flex-column', 'align-items-start'];
        return this.isDisabled ? wrappedClasses.join(' not-editable ') : wrappedClasses;
      },
    },
    methods: {
      isVerticalOption(option) {
        return VERTICAL_OPTIONS.includes(option) || (this.vertical && option === OPT_CENTER);
      },
      getIconFrom(option) {
        const orientation = this.isVerticalOption(option) ? 'Vertical' : 'Horizontal';
        const direction = ucFirst(option);

        return this.isTypeText ? `UilAlign${direction}` : `Uil${orientation}Align${direction}`;
      },
      setPropertyTo(option) {
        const same = this.isSelected(option);
        if (same) return;

        const value = this.isFlexProperty ? CSS_FLEX_MAP[option] || option : option;

        if (this.colorInstance && this.colorInstance.getColoringType() === COLORING_TYPES.IMAGE) {
          this.$emit('alignSelectChange', value);
          return;
        }

        const setter = this.realProperty.includes('globalStyle') ? 'setValueOf' : 'smartSetValueOf';
        this[setter](this.realProperty, value);
      },
      isSelected(option) {
        if (this.isDisabled) return false;

        if (this.isFlexProperty) {
          return this.currentlySet === CSS_FLEX_MAP[option];
        }

        return this.currentlySet === option;
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../../../sass/variables/_colors'

  .align-select
    &.not-editable
      opacity: 0.3
      pointer-events: none
    &.align-select-text
      label
        margin-bottom: 0
    label
      position: relative !important
      top: 0 !important
      left: 0 !important
    .btn
      min-width: min-content
      padding: .25rem .5rem
      margin-bottom: .25rem
      &.selected
        background: $om-orange-500-10 !important
        color: var(--primary)
    &-options-text
      .btn
        margin-bottom: 0
        & + &
          margin-left: .5rem

  .blocks
    display: flex
    .medium-block
      width: 110px !important
    .small-block
      height: 3.75rem
      width: 72px
      border-radius: 4px
      border: 1px solid $om-gray-400
      cursor: pointer
      margin-right: 1rem
      &-icon
        margin-top: 10px
        margin-bottom: 6px
        display: flex
        justify-content: center
        svg
          fill: $om-gray-700
      &-title
        display: flex
        justify-content: center
        color: $om-gray-700
        font-size: 0.625rem
        line-height: 1rem
    .active-block
      height: 3.75rem
      border-radius: 4px
      border: 1px solid $om-orange-500
</style>
