<template lang="pug">
.pane
  sidebar-title
    span {{ $t('editButton') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="general" @change="checkDialInput")
      template(#content)
        sidebar-tab-content#general
          om-dropdown-input(label="onClick" property="data.action" :items="actionTypes")
          .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0(v-if="actionType === 'redirect'") {{ $t('url') }}
          .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0(v-if="actionType === 'dial'") {{ $t('phoneNumber') }}
          .pb-2
            template(v-if="isTypeRedirect")
              om-simple-input(
                label=""
                property="data.redirectUrl"
                placeholder="https://www.example.com/"
              )
              om-switches(
                label="keepQueryParams"
                property="data.keepQueryParams"
                :enabledText="$t('yes')"
                :disabledText="$t('no')"
                tooltip="keepQueryParamsTooltip"
              )
              om-switches(
                label="openInNewTab"
                property="data.newTab"
                :enabledText="$t('yes')"
                :disabledText="$t('no')"
              )
            om-simple-input(
              v-if="actionType === 'dial'"
              ref="phoneNumberInput"
              label=""
              property="data.rawPhoneNumber"
            )
            om-dropdown-input(
              v-if="actionType === 'jumpToPage'"
              label="choosedPage"
              property="data.jumpToPage"
              :items="choosablePages"
              :i18n="false"
            )
            om-dropdown-input(
              v-if="!isTypeBasedOnFeedback && !isTypeCopyCoupon"
              label="reportAs"
              property="data.reportAs"
              :items="reportAsOptions"
            )
            .sidebar-help-wrapper.mt-4.ml-1(v-if="!isTypeCopyCoupon")
              .sidebar-help-content.smaller-size
                | {{ $t(`reportAsTooltip.${selectedElement.data.reportAs}`) }}
            template(v-if="!isTypeBasedOnFeedback && !isTypeCopyCoupon")
              om-switches(
                label="buttonCampaignStatus"
                property="data.status"
                :enabledText="$t('yes')"
                :disabledText="$t('no')"
              )
              om-dropdown-input(
                label="syncToIntegration.label"
                property="data.syncToIntegration"
                :items="syncToIntegrationTypes"
              )
            .sidebar-help-wrapper.mt-4.ml-1(v-if="!isTypeCopyCoupon")
              .sidebar-help-content.smaller-size
                | {{ $t(`syncToIntegration.${selectedElement.data.syncToIntegration}.tooltip`) }}
          template(v-if="isTypeCopyCoupon")
            .sidebar-help-wrapper.ml-1
              .sidebar-help-content.smaller-size
                | {{ $t('couponTooltip') }}
            .mt-5
              .sidebar-input-wrapper-label.pb-0 {{ $t('couponCopy.title') }}
                om-simple-input(property="selectedElement.data.coupon.successCopyText")
            .mt-5(v-if="isActiveShopifyDomain(campaign.domain)")
              .sidebar-input-wrapper-label.pb-0.d-flex.align-items-center {{ $t('couponCopy.missing.title') }}
                om-tooltip.ml-2(color="#23262a") {{ $t('couponCopy.missing.tooltip') }}
              om-simple-input(property="selectedElement.data.coupon.failureCopyText")
        sidebar-tab-predefined-style(property="selectedElement" component="button" :mobile="true")
          template(#before)
            custom-theme-style
            sidebar-accordion(
              v-if="mobilePreview"
              :addDeviceSelector="true"
              :opened="true"
              :title="$t('textSettings')"
            )
              om-range-input(
                label="fontSize"
                property="fontMultiplier"
                :min="0"
                :max="200"
                :step="1"
                unit="%"
                :editMobile="true"
                typeOfComponent="text"
              )
              om-dropdown-input(
                label="mobileAlign"
                property="selectedElement.mobile.textAlign"
                :items="textAlign"
                :editMobile="true"
                typeOfComponent="text"
              )
            om-size(propsProperty="selectedElement" typeOfComponent="button")
        advanced-tab(
          slot="advancedTabContent"
          :spacingProps="{ showPadding: false, typeOfComponent: 'button' }"
        )
</template>
<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import buttonMixin from '@/editor/mixins/button';
  import phoneNumberMixin from '@/editor/mixins/phoneNumber';
  import { debounce, get as _get } from 'lodash-es';
  import itemMixin from '@/editor/mixins/item';

  const frameStore = () => {
    const workspace = document.getElementById('workspaceFrame');
    return workspace.contentWindow.om.store;
  };

  export default {
    components: {
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    mixins: [buttonMixin, phoneNumberMixin, itemMixin],
    data() {
      return {
        textAlign: [
          { key: 'left', value: 'left' },
          { key: 'center', value: 'center' },
          { key: 'right', value: 'right' },
        ],
        iti: null,
        show: false,
        showHover: false,
        showAction: true,
        showMobile: false,
        reportAs: [
          { key: 'conversion', value: 'conversion' },
          { key: 'rejected', value: 'rejected' },
          { key: 'none', value: 'none' },
        ],
        textJustify: [
          { key: 'left', value: 'om-justify-left' },
          { key: 'center', value: 'om-justify-center' },
          { key: 'right', value: 'om-justify-right' },
        ],
        syncToIntegrationTypes: [
          { key: 'syncToIntegration.smartSend.label', value: 'smartSend' },
          { key: 'syncToIntegration.rightNow.label', value: 'rightNow' },
        ],
      };
    },
    computed: {
      ...mapState([
        'selectedElement',
        'selectedPage',
        'pages',
        'mobilePreview',
        'validationError',
        'campaign',
      ]),
      ...mapGetters([
        'isTemplateEditorMode',
        'nestedAccess',
        'pageCount',
        'hasAccountFeature',
        'isActiveShopifyDomain',
      ]),
      actionType: {
        get() {
          return this.getValueOf('data.action');
        },
        set(v) {
          this.setValueOf('data.action', v);
        },
      },
      selectedElementValue() {
        return this.getValueOf('size');
      },
      choosablePages() {
        const pages = frameStore().getters.pages || [];
        const choosablePages = [{ key: 'None', value: 'none' }];
        for (let i = 0; i < pages.length; i++) {
          const page = pages[i];

          if (page.uid === this.selectedPage.uid) {
            continue;
          }

          const key = _get(page, 'data.title') || i + 1;
          choosablePages.push({ key, value: i });
        }
        return choosablePages;
      },
      isTypeRedirect() {
        return this.actionType === 'redirect';
      },
      isTypeCopyCoupon() {
        return this.actionType === 'copyCoupon';
      },
      isTypeBasedOnFeedback() {
        return this.actionType === 'basedOnFeedback';
      },
      reportAsOptions() {
        const options = [...this.reportAs];

        if (this.isEmbedded) {
          options.splice(1, 1);
        }

        return options;
      },
    },
    watch: {
      actionType(type, oType) {
        if (oType === 'dial') this.destroyPhoneNumberInput();
        if (type === 'dial') this.initPhoneNumberInput();
        if (type === 'redirect') {
          this.setValueOf('data.newTab', true);
        }
        if (type === 'copyCoupon') this.handleCopyCoupon();

        this.resetCouponRedeemAction();
      },
      'selectedElement.data.rawPhoneNumber': debounce(function () {
        this.rawPhoneNumberChanged();
      }, 300),
    },
    mounted() {
      this.resetCouponRedeemAction();
    },
    methods: {
      ...mapMutations(['updateData', 'updateStyle']),
      handleCopyCoupon() {
        this.setValueOf('data.reportAs', 'none');
        this.setValueOf('data.status', false);
      },
      actionAccordion(show) {
        if (show && this.actionType === 'dial') {
          this.initPhoneNumberInput();
        }
      },
      resetCouponRedeemAction() {
        if (
          this.actionType === 'couponRedeem' &&
          !this.isActiveShopifyDomain(this.campaign.domain)
        ) {
          this.actionType = 'closePopup';
        }
      },
      checkDialInput() {
        if (this.actionType === 'dial') {
          setTimeout(() => {
            if (this.$refs.phoneNumberInput) {
              this.initPhoneNumberInput();
            }
          }, 200);
        }
      },
    },
  };
</script>

<style lang="sass">
  $flagsImagePath: '~intl-tel-input/build/img/'
  @import '~intl-tel-input/src/css/intlTelInput'

  .intl-tel-input
    display: block
    .country-list
      .divider
        width: auto
        height: auto
        background: initial
</style>
