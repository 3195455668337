<template lang="pug">
.sidebar-input-wrapper.flex-row-baseline.font-dropdown
  .flex.w-100
    span.sidebar-title-tooltip-container.pl-0.mb-1
      label.m-0(style="position: static" v-html="$t(label)")
    multiselect.om-editor-font-style-multiselect(
      v-model="valueObj"
      :options="fonts"
      label="key"
      track-by="value"
      :closeOnSelect="true"
      :searchable="true"
      :allow-empty="false"
      :placeholder="$t('search')"
      selectLabel=""
      deselectLabel=""
      selectedLabel=""
      open-direction="bottom"
    )
      template(slot="singleLabel" slot-scope="props")
        span.option__desc
          span.option__title.text-overflow(:style="`font-family: '${props.option.key}'`") {{ props.option.key }}
      template(slot="option" slot-scope="props")
        span.option__desc
        span.option__title(:style="`font-family: ${props.option.key}`") {{ props.option.key }}
  om-button.mt-2(secondary block small @click="$modal.show('font-management')") {{ $t('editorBeta.manageFonts') }}
    UilListUl(slot="icon" size="1.715em")
</template>
<script>
  import { mapGetters, mapMutations, mapState } from 'vuex';
  import { getMostUsedFonts, translatedThemeKitFontOptions } from '@/editor/util/getMostUsedFonts';
  import { UilListUl } from '@iconscout/vue-unicons';
  import itemMixin from '../../../mixins/item';

  export default {
    components: { UilListUl },
    mixins: [itemMixin],
    props: {
      property: { type: String, required: true },
      label: { type: String, required: true },
      i18n: { type: Boolean, default: true },
      defaultValue: String,
      typeOfComponent: { type: String, default: 'global' },
    },
    data() {
      return {
        valueObj: null,
      };
    },
    computed: {
      ...mapState(['themeKit', 'mobilePreview']),
      ...mapGetters(['installedFonts']),
      ...mapGetters({
        userId: 'databaseId',
      }),
      fonts() {
        const themeKitFonts = this.getThemeKitFonts();
        const fonts = getMostUsedFonts(this.userId, this.installedFonts).map(
          ({ value, label }) => ({
            value,
            key: label,
          }),
        );

        return [...themeKitFonts, ...fonts];
      },
      value: {
        get() {
          return this.getValueOf(this.property);
        },
        set(value) {
          this.setValueOf(this.property, value);
          window.om.bus.$emit('historySave');
          window.om.bus.$emit('userInputChange', { property: this.property, value });
        },
      },
    },
    watch: {
      valueObj(newVal) {
        this.value = newVal.value;
      },
      mobilePreview() {
        this.updateValueObj();
      },
    },
    mounted() {
      this.$nextTick(() => {
        const font = this.fonts.find((f) => f.value === this.value);
        this.valueObj = {
          key: font ? font.key : 'Open Sans',
          value: font ? this.value : 'open-sans',
        };
      });
      this.$bus.$on('themeKitFontChanged', ({ index }) => {
        this.$nextTick(() => {
          const valueKey = `om-font-${index + 1}`;
          if (valueKey !== this.value) return;

          const themeKitFonts = this.getThemeKitFonts();
          this.valueObj = {
            key: themeKitFonts[index].key ?? 'Open Sans',
            value: themeKitFonts[index].value ?? 'open-sans',
          };
        });
      });
    },
    beforeDestroy() {
      this.$bus.$off('themeKitFontChanged');
    },
    methods: {
      ...mapMutations(['updateStyle']),
      getThemeKitFonts() {
        return translatedThemeKitFontOptions('key', this.themeKit.fonts[0], this.themeKit.fonts[1]);
      },
      updateValueObj() {
        const font = this.fonts.find((f) => f.value === this.value);
        this.valueObj = {
          key: font ? font.key : 'Open Sans',
          value: font ? this.value : 'open-sans',
        };
      },
    },
  };
</script>

<style lang="sass" scoped>
  .text-overflow
    max-width: 12.5rem !important
  .sidebar.editor
    .font-dropdown
      flex-direction: column
      align-items: flex-start
      label
        position: relative
        top: auto
        left: auto
      .multiselect
        margin-top: .5rem
        margin-left: 0 !important
        &__select
          width: auto !important
          &:before
            color: rgba(80, 87, 99, 1)
            padding: 0 .5rem
        &__tags
          width: auto !important
        &__content-wrapper
          width: 100%
          max-width: unset !important
</style>
