<template lang="pug">
.pane.new-editor
  sidebar-title
    span {{ $t('editCoupon') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="general")
      template(#content)
        sidebar-tab-content#general
          .user-info-message.alert.alert-danger.mx-3.font-size-0--875(v-if="noCouponUploaded") {{ $t('uploadCoupons') }}
          .sidebar-input-wrapper.sidebar-input-wrapper-label.pt-0.pb-0.pane-title.mt-3 {{ $t('discountPane.title') }}

          om-dropdown-input.discount-type-select.wide-select-100.pt-1.pb-1(
            label=""
            property="data.coupon.type"
            :items="couponTypes"
          )
          template(v-if="coupon.type === 'shopify_automatic'")
            .sub-title.ml-1.mr-1 {{ $t('discountPane.automatic.subTitle') }}
          template(v-if="coupon.type === 'fixed'")
            .sub-title.ml-1.mb-6.mr-1 {{ $t('discountPane.fixed.subTitle') }}
            .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('coupon') }}
            om-simple-input(label="" property="data.coupon.fixedCoupon")
            .coupon-data.mb-1(v-if="showFixedCouponSettings")
              .flex-column
                .shopify-coupon-info-box(
                  v-if="showFixedCouponInfo"
                  :class="[shopifyCouponInfo.type]"
                )
                  UilCheckCircle(v-if="shopifyCouponInfo.type === 'success'")
                  UilExclamationTriangle(v-if="shopifyCouponInfo.type === 'warning'")
                  UilExclamationTriangle(v-if="shopifyCouponInfo.type === 'error'")
                  .coupon-info-text(v-html="shopifyCouponInfo.text")
                om-button.create-coupon-button.mt-5(
                  primary
                  small
                  block
                  @click="createCoupon"
                  v-if="showCreateCouponButton"
                )
                  span {{ $t('discountPane.fixed.createDiscountCode') }}
                .shopify-coupon-details.mt-6.mb-1(v-if="showCouponDetails")
                  .shopify-coupon-title {{ shopifyFixedCoupon.couponData.code }}
                  ul.shopify-coupon-details-list
                    li.shopify-coupon-details-list-item(v-for="detail in shopifyFixCouponDetails") {{ detail }}
          template(v-if="coupon.type === 'unique'")
            .sub-title.ml-1.mb-3.mr-1 {{ $t('discountPane.fixedOrUnique.subTitle') }}
            .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('ifCouponLimitReached') }}
            .pb-2
              om-dropdown-input.wide-select-100(
                label=""
                property="data.coupon.fallbackAction"
                :items="fallbackActions"
              )
            template(v-if="fallbackAction === 'text'")
              .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('text') }}
              .pb-2
                om-simple-input(label="" property="data.coupon.fallbackCoupon")
            .sidebar-input-wrapper.flex-row-baseline.flex-column.align-items-start.pt-0.pb-2
              .sidebar-input-wrapper.sidebar-input-wrapper-label.px-0 {{ $t('availableCoupons') }}: ({{ couponCounts.available }} / {{ allCouponCount }})
              .sidebar-input-wrapper.sidebar-input-wrapper-label.px-0(v-if="couponCounts.reserved") {{ $t('pendingCoupons') }}: {{ couponCounts.reserved }}
              .d-flex.flex-row.align-items-center.w-100
                .progress.flex-grow-1
                  .progress-bar(role="progressbar" :style="progressBarStyle")
              .pt-2.w-100
                .button.button-primary.button-primary-large.mt-4(@click="showCouponSettings") {{ $t('editCoupons') }}
          template(v-if="coupon.type === 'shopify_automatic'")
            .pt-4.pb-4
              .blocks.ml-1
                .small-block(
                  v-for="type in automaticTypes"
                  :key="type.key"
                  :class="{ 'active-block': coupon.automatic.type === type.value }"
                  @click="toggleAutomaticType(type.value)"
                )
                  .small-block-icon(:class="{ [type.key]: true }")
                    component(:is="type.svg")
                  .small-block-title
                    span {{ $t(`discountPane.automatic.${type.key}`) }}
            template(v-if="coupon.automatic.type === 'percentage'")
              .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('discountPane.automatic.percentageValue') }}
              .percentage-input-wrapper
                om-simple-input(
                  label=""
                  type="number"
                  property="data.coupon.automatic.percentageValue"
                  placeholder="e.g. 10"
                )
            template(v-else)
              .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('discountPane.automatic.fixedValue', { currency }) }}
              om-simple-input(
                label=""
                type="number"
                property="data.coupon.automatic.fixedValue"
                placeholder="e.g. 10"
              )
            .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0 {{ $t('discountPane.automatic.prefix') }}
              om-tooltip.ml-2
                span {{ $t('discountPane.automatic.prefixTooltip') }}
            .pb-2
              om-simple-input(
                label=""
                property="data.coupon.automatic.prefix"
                placeholder="e.g. BLACKFRIDAY"
              )
            om-switches.expiration-switch(
              label="discountPane.automatic.expiration"
              property="data.coupon.automatic.expiration"
            )
            template(v-if="coupon.automatic.expiration")
              .radio-holder.ml-2
                .row
                  input#radio-absolute(
                    type="radio"
                    name="coupon-expiration-type"
                    value="absolute"
                    v-model="expirationType"
                  )
                  label.radio-label(for="radio-absolute") {{ $t('discountPane.automatic.fixedExpiration') }}
                .date-time-holder.d-flex.align-items-center(v-if="isAbsoluteExpiration")
                  .sidebar-input-wrapper
                    v-date-picker.form-control.d-flex.brand-date-picker(
                      mode="single"
                      :min-date="new Date()"
                      :formats="{ title: 'MMMM YYYY', weekdays: 'W', navMonths: 'MMM', input: [dateFormat], dayPopover: shortDateFormat }"
                      v-model="expiresDate"
                      :class="{ 'is-invalid': isExpiresDateInValid }"
                    )
                      template(v-slot="{ inputValue, inputEvents }")
                        input(:value="inputValue" v-on="inputEvents")
                        .brand-date-picker-icon
                          fa-icon(variant="fa-calendar-o" size="1.25")
                  om-dropdown-input.time-input(
                    label=""
                    v-model="expiresHour"
                    :i18n="false"
                    :items="hours"
                    :class="{ 'validation-failed': isExpiresDateInValid }"
                  )
                  span.d-flex.align-items-center :
                  om-dropdown-input.time-input(
                    label=""
                    v-model="expiresMinute"
                    :i18n="false"
                    :items="minutes"
                    :class="{ 'validation-failed': isExpiresDateInValid }"
                  )
                .validation-failed-msg(v-if="isExpiresDateInValid") {{ $t(validationError.msg) }}
                .sub-title.mb-3(
                  v-if="isAbsoluteExpiration"
                  v-html="$t('discountPane.automatic.fixedSubTitle', { offset: getDiffToGMT() })"
                )
                .row
                  input#radio-relative(
                    type="radio"
                    name="coupon-expiration-type"
                    value="relative"
                    v-model="expirationType"
                  )
                  label.radio-label(for="radio-relative") {{ $t('discountPane.automatic.afterDisplayExpiration') }}
                .pb-2.d-flex.date-time-holder.align-items-center(v-if="!isAbsoluteExpiration")
                  om-simple-input.expires-in-input(
                    label=""
                    type="number"
                    property="data.coupon.automatic.expiresIn"
                    placeholder="e.g. 15"
                  )
                  om-dropdown-input.expires-in-unit-input(
                    label=""
                    property="data.coupon.automatic.expiresInUnit"
                    :items="expiresInUnits"
                  )
          template(v-if="coupon.type === 'followup'")
            .sub-title.ml-1.mb-5.mr-1 {{ $t('discountPane.followup.subTitle') }}
            om-switches.expiration-switch.limit-switch(
              v-if="isActiveShopify"
              label="discountPane.followup.limit"
              property="data.coupon.followup.limit"
            )
            .validation-failed-msg.limit-required(v-if="isLimitRequired") {{ $t(validationError.msg) }}
            template(v-if="coupon.followup.limit")
              .d-flex.mb-1.mt-1.align-items-start
                om-simple-input.expires-in-input.limit-in-input(
                  label=""
                  type="number"
                  property="data.coupon.followup.expiresIn"
                  placeholder="e.g. 15"
                )
                om-dropdown-input.limit-in-unit-input(
                  label=""
                  property="data.coupon.followup.expiresInUnit"
                  :items="expiresInUnits"
                )
            .followup-rules.mt-4
              .followup-rule-title(v-html="$t('discountPane.followup.rulesTitle')")
              div(v-html="$t('discountPane.followup.rulesText')")
              ul.follow-up-description-list
                li {{ $t('discountPane.followup.userRedeem') }}
                li {{ $t('discountPane.followup.userClose') }}
                li {{ $t('discountPane.followup.expirationEnds') }}
              div(
                v-if="$i18n.locale === 'en'"
                v-html="$t('discountPane.followup.userGuide', { link: 'https://support.optimonk.com/hc/en-us/articles/4422002586130-Discount-code-reminder' })"
              )
          template(v-if="showAutoRedeem")
            om-switches.auto-reedem(
              label="autoRedeem"
              property="data.coupon.autoRedeem"
              tooltip="autoRedeemTooltip"
            )
          .loading-coupon(
            v-if="loadingFixedCoupon && coupon.fixedCoupon && coupon.type === 'fixed'"
          )
            img(:src="require('@/assets/editor/svg/loader.svg')")
          set-coupon-copied-text
        sidebar-tab-predefined-style(property="selectedElement" component="coupon" :mobile="true")
          template(#before)
            custom-theme-style
            om-size(propsProperty="selectedElement" typeOfComponent="coupon")
            om-style(
              subPath="selectedElement.subElements.couponText.desktop"
              propsProperty="selectedElement"
              typeOfComponent="coupon"
            )
        advanced-tab(slot="advancedTabContent" :spacingProps="spacingProps")
</template>

<script>
  import moment from 'moment';
  import { mapState, mapActions, mapGetters, mapMutations, createNamespacedHelpers } from 'vuex';
  import PercentageSVGVue from '@/editor/components/svg/PercentageSVG.vue';
  import MoneyFixedSVGVue from '@/editor/components/svg/MoneyFixedSVG.vue';
  import itemMixin from '@/editor/mixins/item';
  import GET_SHOP_INFO from '@/graphql/GetEcommerceShopInfo.gql';
  import LOOKUP_COUPON from '@/graphql/LookupCoupon.gql';
  import dateFormat from '@/mixins/dateFormat';
  import cookie from '@/mixins/cookie';

  const { mapGetters: editorOuterGetters } = createNamespacedHelpers('editorOuter');

  export default {
    components: {
      DateTimepicker: () => import('@/components/DateTimepicker'),
      UilCheckCircle: () => import('@iconscout/vue-unicons'),
      UilInfoCircle: () => import('@iconscout/vue-unicons'),
      UilExclamationTriangle: () => import('@iconscout/vue-unicons'),
      Badge: () => import('@/components/Badge.vue'),
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
      SetCouponCopiedText: () =>
        import('@/editor/components/sidebar/components/SetCouponCopiedText.vue'),
    },
    mixins: [itemMixin, dateFormat, cookie],
    data() {
      return {
        moment,
        fallbackActions: [
          { key: 'stopCampaign', value: 'hide' },
          { key: 'showText', value: 'text' },
        ],
        automaticTypes: [
          { key: 'percentage', value: 'percentage', svg: PercentageSVGVue },
          { key: 'fixed', value: 'fixed', svg: MoneyFixedSVGVue },
        ],
        expiresInUnits: [
          { key: 'discountPane.automatic.days', value: 'day' },
          { key: 'discountPane.automatic.hours', value: 'hour' },
          { key: 'discountPane.automatic.minutes', value: 'minute' },
        ],
        currency: '',
        hours: Array.from({ length: 24 }, (_, i) => ({ value: i, key: this.timeUnitFormat(i) })),
        minutes: Array.from({ length: 60 }, (_, i) => ({ value: i, key: this.timeUnitFormat(i) })),
        isTyping: false,
        typingTimeout: null,
        shopifyFixedCoupon: null,
        loadingFixedCoupon: false,
        lookupError: false,
        fixedCouponFeatures: [
          {
            key: 'VALID_FOR_SPECIFIC_PRODUCTS',
            text: this.$t('discountPane.fixed.specificProducts'),
          },
          {
            key: 'VALID_FOR_SPECIFIC_GROUP_OF_CUSTOMERS',
            text: this.$t('discountPane.fixed.specificCustomers'),
          },
          {
            key: 'VALID_FOR_ONCE_PER_CUSTOMER',
            text: this.$t('discountPane.fixed.appliesOncePerCustomer'),
          },
        ],
      };
    },
    computed: {
      ...mapState([
        'selectedElement',
        'showFormManager',
        'couponCounts',
        'validationError',
        'campaign',
      ]),
      ...mapGetters([
        'hasActiveShopify',
        'isActiveShopifyDomain',
        'hasAccountFeature',
        'isTemplateEditorMode',
        'isEmbedded',
      ]),
      ...editorOuterGetters(['isGamificationTemplate']),
      expiresDate: {
        get() {
          return new Date(this.coupon.automatic.expirationTime);
        },
        set(v) {
          const newDate = moment(v);
          const expirationTime = moment(this.coupon.automatic.expirationTime);
          expirationTime.year(newDate.year());
          expirationTime.month(newDate.month());
          expirationTime.date(newDate.date());
          this.setExpirationTime(expirationTime);
        },
      },
      expiresHour: {
        get() {
          return moment(this.coupon.automatic.expirationTime).hour();
        },
        set(v) {
          const expirationTime = moment(this.coupon.automatic.expirationTime);
          expirationTime.hour(v);
          this.setExpirationTime(expirationTime);
        },
      },
      expiresMinute: {
        get() {
          return moment(this.coupon.automatic.expirationTime).minute();
        },
        set(v) {
          const expirationTime = moment(this.coupon.automatic.expirationTime);
          expirationTime.minute(v);
          this.setExpirationTime(expirationTime);
        },
      },
      expirationType: {
        get() {
          return this.coupon.automatic.expirationType;
        },
        set(value) {
          this.setValueOf('data.coupon.automatic.expirationType', value);
        },
      },
      isAbsoluteExpiration() {
        return this.expirationType === 'absolute';
      },
      isVersion3() {
        return this.campaign.version === 3;
      },
      couponTypes() {
        let types = [
          { key: 'discountPane.types.fix', value: 'fixed' },
          { key: 'discountPane.types.unique', value: 'unique' },
          { key: 'discountPane.types.followup', value: 'followup' },
        ];
        const isV3Embedded = this.isEmbedded && this.isVersion3;

        if (this.isActiveShopify || this.isTemplateEditorMode) {
          // need to insert to the second place, not the end of the array
          types.splice(1, 0, { key: 'discountPane.types.automatic', value: 'shopify_automatic' });
        }

        if (this.isGamificationTemplate) {
          types = [{ key: 'discountPane.types.gamification', value: 'gamification' }];
          this.setValueOf('data.coupon.type', 'gamification');
        }

        const spliceSize = this.isActiveShopify ? 3 : 2;
        if (isV3Embedded) {
          types.splice(spliceSize);
        }

        return types;
      },
      isActiveShopify() {
        return this.isActiveShopifyDomain(this.campaign.domain);
      },
      coupon() {
        return this.selectedElement.data.coupon;
      },
      fallbackAction() {
        return this.coupon.fallbackAction;
      },
      allCouponCount() {
        const { available, shown, reserved } = this.couponCounts;
        return available + shown + reserved;
      },
      progressBarStyle() {
        const percentage = (this.couponCounts.available / this.allCouponCount) * 100;
        return {
          width: `${percentage}%`,
        };
      },
      noCouponUploaded() {
        return (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('noCouponUploaded')
        );
      },
      isLimitRequired() {
        return (
          !this.coupon.followup.limit &&
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('data.coupon.followup.limit')
        );
      },
      isExpiresDateInValid() {
        return (
          this.isAbsoluteExpiration &&
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('data.coupon.automatic.expirationTime')
        );
      },
      shopifyAdminLink() {
        return `http://${this.campaign.domain}/admin/discounts`;
      },
      fixedCouponCases() {
        return {
          notExists: {
            type: 'warning',
            text: this.$t('discountPane.fixed.couponDoesNotExist'),
          },
          expired: {
            type: 'warning',
            text: this.$t('discountPane.fixed.couponExpired', { link: this.shopifyAdminLink }),
          },
          used: {
            type: 'warning',
            text: this.$t('discountPane.fixed.couponUsed', { link: this.shopifyAdminLink }),
          },
          exists: {
            type: 'success',
            text: this.$t('discountPane.fixed.couponExists', { link: this.shopifyAdminLink }),
          },
          created: {
            type: 'success',
            text: this.$t('discountPane.fixed.couponCreated', { link: this.shopifyAdminLink }),
          },
          error: {
            type: 'error',
            text: this.$t('discountPane.fixed.couponLookupError', { link: this.shopifyAdminLink }),
          },
        };
      },
      shopifyCouponInfo() {
        if (this.lookupError) {
          return this.fixedCouponCases.error;
        }

        if (this.shopifyFixedCoupon?.couponData === null) {
          return this.fixedCouponCases.notExists;
        }

        if (this.shopifyFixedCoupon?.userCreated) {
          return this.fixedCouponCases.created;
        }

        if (this.shopifyFixedCoupon?.couponData.status === 'EXPIRED') {
          return this.fixedCouponCases.expired;
        }

        if (
          this.shopifyFixedCoupon?.couponData &&
          this.shopifyFixedCoupon?.couponData.usageCount ===
            this.shopifyFixedCoupon?.couponData.usageLimit
        ) {
          return this.fixedCouponCases?.used;
        }

        if (this.shopifyFixedCoupon?.couponData) {
          return this.fixedCouponCases.exists;
        }

        return null;
      },
      shopifyFixCouponDetails() {
        if (!this.shopifyFixedCoupon?.couponData) {
          return null;
        }

        return [
          ...this.getTypeDetails(),
          ...this.getValidityDetails(),
          ...this.getUsageDetails(),
          ...this.getFixedCouponFeatures(),
          ...this.getMinimumPurchase(),
        ];
      },
      showAutoRedeem() {
        return (
          this.coupon.type !== 'followup' &&
          this.isActiveShopify &&
          (this.coupon.type !== 'fixed' ||
            (this.coupon.type === 'fixed' &&
              this.shopifyFixedCoupon &&
              !this.loadingFixedCoupon &&
              this.coupon.fixedCoupon))
        );
      },
      showFixedCouponInfo() {
        return !this.loadingFixedCoupon && this.shopifyCouponInfo && this.coupon.fixedCoupon;
      },
      showCouponDetails() {
        return this.showFixedCouponInfo && this.shopifyFixedCoupon?.couponData;
      },
      showCreateCouponButton() {
        return (
          this.shopifyFixedCoupon?.couponData === null &&
          this.coupon.fixedCoupon &&
          !this.loadingFixedCoupon &&
          !this.lookupError
        );
      },
      showFixedCouponSettings() {
        return this.showFixedCouponInfo || this.showCreateCouponButton || this.showCouponDetails;
      },
      spacingProps() {
        return {
          showPadding: false,
          typeOfComponent: 'cupon',
        };
      },
    },

    watch: {
      'showFormManager.couponSettings': function (v) {
        if (v === false) {
          this.fetchCouponCounts();
        }
      },
      'coupon.type': function (v) {
        if (v === 'unique') {
          this.fetchCouponCounts();
        }
      },
      'coupon.fixedCoupon': function () {
        if (this.isActiveShopify) {
          this.isTyping = true;
          if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
          }
          this.typingTimeout = setTimeout(() => {
            this.isTyping = false;
          }, 1000);
          if (this.validationError?.property === 'data.coupon.fixedCoupon') {
            this.updateValidationErrorProperty(null);
          }
          if (!this.coupon.fixedCoupon) {
            this.shopifyFixedCoupon = null;
          }
        }
      },
      isTyping(value) {
        if (!value && this.coupon.fixedCoupon?.trim()) {
          this.lookupCoupon(this.coupon.fixedCoupon);
        }
      },
    },
    created() {
      this.$bus.$on('couponCreated', this.setCreatedCoupon);
      document.addEventListener('visibilitychange', this.lookupSavedCoupon);
    },
    mounted() {
      if (this.coupon.type === 'unique') this.fetchCouponCounts();
      if (this.isActiveShopify) {
        this.getShopifyShopCurrency();
        if (this.coupon.fixedCoupon) {
          this.lookupCoupon(this.coupon.fixedCoupon);
        }
      }
    },
    beforeDestroy() {
      document.removeEventListener('visibilitychange', this.lookupSavedCoupon);
      this.$bus.$off('couponCreated', this.setCreatedCoupon);
    },
    methods: {
      ...mapActions(['fetchCouponCounts']),
      ...mapMutations(['changeFormManagerVisibility', 'updateValidationErrorProperty']),
      toggleAutomaticType(type) {
        this.setValueOf('data.coupon.automatic.type', type);
      },
      showCouponSettings() {
        this.$modal.show('coupon-settings');
      },
      async getShopifyShopCurrency() {
        const { data } = await this.$apollo.query({
          query: GET_SHOP_INFO,
          variables: { type: 'shopify', domain: this.campaign.domain },
        });

        if (data && data.getShopInfo) {
          const { moneyFormat } = data.getShopInfo;
          this.currency = moneyFormat.replace(/{{.*amount.*}}/gi, '').trim();
        }
      },

      setExpirationTime(time) {
        this.setValueOf('data.coupon.automatic.expirationTime', time.toISOString());
      },
      lookupCoupon(code) {
        this.loadingFixedCoupon = true;
        this.lookupError = false;
        this.$apollo
          .query({
            query: LOOKUP_COUPON,
            variables: {
              code,
              shopId: this.campaign.domain,
            },
          })
          .then(({ data: { couponData }, errors }) => {
            this.loadingFixedCoupon = false;
            this.lookupError = false;
            this.shopifyFixedCoupon = { couponData };

            if (errors?.length) {
              this.lookupError = true;
            }
          })
          .catch(() => {
            this.lookupError = true;
            this.loadingFixedCoupon = false;
          });
      },
      createCoupon() {
        this.changeFormManagerVisibility({
          show: 'couponCreate',
          createDiscountProps: {
            currency: this.currency,
            couponCode: this.coupon.fixedCoupon,
            shopifyAdminLink: this.shopifyAdminLink,
          },
        });
      },
      processBasicDiscountDetails() {
        const { basicRules } = this.shopifyFixedCoupon.couponData;
        const value =
          basicRules.type === 'DiscountPercentage'
            ? `${(basicRules.value * 100).toFixed(0)}%`
            : `${basicRules.value} ${basicRules.currencyCode}`;

        return [this.$t('discountPane.fixed.discount', { value })];
      },
      processShippingDiscountDetails() {
        const {
          shippingDiscountRules: { maximumShippingPrice, specificCountries },
        } = this.shopifyFixedCoupon.couponData;

        return [
          this.$t('discountPane.fixed.freeShipping'),
          ...(specificCountries
            ? [this.$t('discountPane.fixed.specificCountries')]
            : [this.$t('discountPane.fixed.allCountries')]),
          ...(maximumShippingPrice
            ? [
                this.$t('discountPane.fixed.maximumShippingPrice', {
                  shippingMax: `${maximumShippingPrice.amount} ${maximumShippingPrice.currencyCode}`,
                }),
              ]
            : []),
        ];
      },
      processBxGyDiscountDetails() {
        const {
          buyXgetYRules: { type, amount, buyQuantity, discountPercentage, getQuantity },
        } = this.shopifyFixedCoupon.couponData;

        if (type === 'DiscountQuantity') {
          if (discountPercentage === 1) {
            return [
              this.$t('discountPane.fixed.buyXgetY', {
                x: buyQuantity,
                y: getQuantity,
              }),
            ];
          }
          return [
            this.$t('discountPane.fixed.buyXgetYDiscounted', {
              x: buyQuantity,
              y: getQuantity,
              discount: `${discountPercentage * 100}%`,
            }),
          ];
        }
        if (type === 'DiscountPurchaseAmount') {
          if (discountPercentage === 1) {
            return [
              this.$t('discountPane.fixed.buyAmountgetY', {
                x: amount,
                y: getQuantity,
              }),
            ];
          }
          return [
            this.$t('discountPane.fixed.buyAmountgetYDiscounted', {
              x: amount,
              y: getQuantity,
              discount: `${discountPercentage * 100}%`,
            }),
          ];
        }
      },
      getTypeDetails() {
        const types = {
          DiscountCodeBasic: this.processBasicDiscountDetails,
          DiscountCodeFreeShipping: this.processShippingDiscountDetails,
          DiscountCodeBxgy: this.processBxGyDiscountDetails,
        };
        const { type } = this.shopifyFixedCoupon.couponData;

        return types[type]();
      },
      getValidityDetails() {
        if (!this.shopifyFixedCoupon?.couponData) {
          return null;
        }
        const { startsAt, endsAt } = this.shopifyFixedCoupon.couponData;

        const dateFormat = this.$i18n.locale === 'hu' ? 'YYYY-MM-DD HH:mm' : 'MM/DD/YYYY hh:mm a';

        return [
          ...(startsAt
            ? [
                this.$t('discountPane.fixed.validFrom', {
                  startsAt: moment(startsAt).format(dateFormat),
                }),
              ]
            : []),
          ...(endsAt
            ? [
                this.$t('discountPane.fixed.validUntil', {
                  endsAt: moment(endsAt).format(dateFormat),
                }),
              ]
            : []),
        ];
      },

      getUsageDetails() {
        if (!this.shopifyFixedCoupon?.couponData) {
          return [];
        }
        const { usageLimit, usageCount } = this.shopifyFixedCoupon.couponData;

        const details = [];

        const usageParams = { usageLimit, usageCount };
        for (const key in usageParams) {
          if (usageParams[key]) {
            details.push(
              this.$t(`discountPane.fixed.${key}`, {
                ...(usageParams[key] && { value: usageParams[key] }),
              }),
            );
          }
        }
        return details;
      },

      getFixedCouponFeatures() {
        if (!this.shopifyFixedCoupon?.couponData) {
          return [];
        }
        const { features } = this.shopifyFixedCoupon.couponData;

        if (!features) return [];

        return features.reduce((featureTexts, feature) => {
          const fixedFeature = this.fixedCouponFeatures.find((f) => f.key === feature);
          if (fixedFeature) {
            featureTexts.push(fixedFeature.text);
          }
          return featureTexts;
        }, []);
      },

      getMinimumPurchase() {
        if (!this.shopifyFixedCoupon?.couponData) {
          return [];
        }
        const { minimumPurchase } = this.shopifyFixedCoupon.couponData;

        if (minimumPurchase) {
          const purchaseValue =
            minimumPurchase.type === 'greaterThanOrEqualToQuantity'
              ? `${minimumPurchase.value} items`
              : `${minimumPurchase.value} ${minimumPurchase.currencyCode}`;
          return [this.$t('discountPane.fixed.minimumPurchase', { purchaseValue })];
        }
        return [];
      },
      lookupSavedCoupon() {
        if (
          this.coupon.fixedCoupon &&
          !document.hidden &&
          (this.shopifyFixedCoupon?.couponData === null ||
            this.shopifyFixedCoupon?.couponData.status === 'EXPIRED' ||
            (this.shopifyFixedCoupon?.couponData &&
              this.shopifyFixedCoupon?.couponData.usageCount ===
                this.shopifyFixedCoupon?.couponData.usageLimit))
        ) {
          this.lookupCoupon(this.coupon.fixedCoupon);
        }
      },
      setCreatedCoupon({ couponData }) {
        this.shopifyFixedCoupon = { couponData, userCreated: true };
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import "@/sass/variables/_colors.sass"
  .ml-1
    margin-left: 0rem !important
  .sidebar-input-wrapper-label
    font-size: 0.75rem
    color: $om-gray-700
  .sidebar-input-wrapper
    ::v-deep .epic-simple-input
      input
        font-size: 14px
        padding: 8px 12px
        line-height: 22px
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0
      input[type=number]
        -moz-appearance: textfield
      select
        height: 40px !important
        font-size: 14px
  .brand-editor .editor-wrapper .sidebar-input-wrapper
    padding: 0.25rem 0rem
  .badge-wrapper
    min-height: 20px
  .pane-title
    font-size: 0.75rem
    color: $om-gray-700
  .discount-type-select
    margin-left: unset !important
    ::v-deep .select-arrow
      top: 14px !important
  .radio-holder
    padding: 0 .25rem
    .radio-label
      margin-top: 2px
      font-size: 0.75rem
      color: $om-gray-700
      margin-left: 8px
  .date-time-holder
    margin-left: -0.7rem
    .sidebar-input-wrapper
      padding: .75rem 0.5rem
    .time-input
      width: 60px !important
      ::v-deep .epic-simple-input
        position: relative
      ::v-deep select
        font-size: 12px !important
        height: 40px !important
        width: 50px !important
        padding-left: 5px
      ::v-deep .select-arrow
        top: 9px !important
        right: 10px !important
      ::v-deep .sidebar-title-tooltip-container
        display: none
    .expires-in-unit-input
      width: 100%
      ::v-deep .epic-simple-input
        width: 100%
        .select-arrow
          top: 1rem
          right: 1.5rem
      ::v-deep select
        width: 100%
        height: 40px !important
    .validation-failed ::v-deep select
      background: rgba(255, 0, 0, 0.1) !important
      border-color: #dc3545 !important
      option
        background: white
    span
      max-width: 5px
    .new-badge
      width: 52px
      height: 20px
  .blocks
    display: flex
    .small-block
      height: 60px
      width: 120px
      border-radius: 4px
      border: 1px solid $om-gray-400
      cursor: pointer
      &:last-of-type
        margin-left: 8px
      &-icon
        margin-top: 12px
        margin-bottom: 8px
        display: flex
        justify-content: center
      &-title
        display: flex
        justify-content: center
        color: $om-gray-700
        font-size: 0.625rem
        line-height: 1rem
    .active-block
      height: 60px
      width: 120px
      border-radius: 4px
      border: 1px solid $om-orange-500

  .sub-title
    margin-left: -0.7rem
    font-size: 0.75rem
    line-height: 1rem
    color: $om-gray-600
  .expires-in-input
    padding-right: 0
    width: 72px
    margin-right: 12px
    ::v-deep input
      height: 40px
      min-width: 72px
  .brand-date-picker-icon
    position: absolute
    right: 8px
    top: 5px
  .brand-date-picker
    min-height: 40px
    font-size: 13px
    padding: 0 !important
    margin-right: 5px
    width: 120px
    input
      height: 40px
      padding-left: 5px
      width: 120px
      border: 0
      background: transparent
  .validation-failed-msg
    color: #f88
    font-size: .625rem
    margin-left: -0.7rem
    padding: 0.5em
    background: lighten(#f88, 18%)
    border-radius: 2px
    border: 1px solid #f88
  input[type='radio']
    width: 22px
    height: 22px
  input[type="radio"]:after
    content: url(~@/assets/editor/svg/RadioCheckBox.svg)
  input[type='radio']:checked:after
    width: 22px
    height: 22px
    content: url(~@/assets/editor/svg/RadioCheckBoxChecked.svg)
  .expiration-switch ::v-deep label
    font-weight: 500
  .auto-reedem

    ::v-deep label
      font-weight: 500

    ::v-deep .smaller-size
      color: $om-gray-600

    ::v-deep .sidebar-help-wrapper
      margin-top: 8px !important

  .percentage-input-wrapper
    position: relative

  .percentage-input-wrapper::after
    position: absolute
    top: 16px
    right: 1.2em
    transition: all .05s ease-in-out

  .percentage-input-wrapper::after
    content: url(~@/assets/editor/svg/Percentage.svg)

  .old-editor
    .shopify-coupon-title
      color: $om-gray-800
    .shopify-coupon-details-list-item
      color: $om-gray-700 !important
    .ml-1
      margin-left: 1rem !important
    .ml-2
      margin-left: 1.5rem !important
    .percentage-input-wrapper::after
      top: 16px
      right: 2.2em
    .sidebar-input-wrapper-label
      padding: 0.25rem 1rem 1.5rem 1rem
    .sidebar-input-wrapper
      padding: 0.25rem 1rem 0.25rem 1rem
    .blocks
      .small-block
        width: 8.75rem
    .discount-type-select
      ::v-deep .select-arrow
        top: 1rem !important
    .date-time-holder
      margin-left: -1.5rem
      .sidebar-input-wrapper
        padding: .75rem 0.5rem
      .time-input
        margin-left: -1px
      .expires-in-unit-input
        width: 98%
      span
        max-width: 10px
        padding-left: 6px
    .fixed
      margin-left: 7px

    .expires-in-unit-input
      ::v-deep .epic-simple-input
        .select-arrow
          top: 1.5rem
          right: 1.5rem

  /* handle Firefox (arrows always shown) */
  @supports (-moz-appearance:none)
    .percentage-input-wrapper::after
      right: 2.7em

  /* fix coupon*/
  .coupon-data
    padding: 12px 0 !important
    margin-top: -4px

  .shopify-coupon-info-box
    display: flex
    align-items: center
    width: 100%

    svg
      height: 24px
      width: 24px
      margin-right: 10px
      flex-shrink: 0

    &.success
      ::v-deep svg
        color: #239e78

    &.warning
      ::v-deep svg
        color: #f0c920

    &.error
      ::v-deep svg
        color: #be1820

    .coupon-info-text
      font-size: 0.625rem
      color: #505763
      margin-bottom: 0

      ::v-deep a
        font-size: 0.625rem
        color: #505763
        text-decoration: underline

  .shopify-coupon-details
    padding: 12px
    width: 100%
    border-radius: 8px
    border: 1px dashed $om-gray-400

    .shopify-coupon-title
      font-weight: 700
      font-size: 0.75rem
      margin-bottom: 4px

    .shopify-coupon-details-list
      margin-bottom: 0
      padding-left: 26px

      .shopify-coupon-details-list-item
        font-size: 0.75rem
        color: $om-gray-700

  .loading-coupon
    height: 132px
    position: relative
    display: flex
    align-items: center
    justify-content: center
    z-index: 10
    font: normal normal normal 0.875rem/1 FontAwesome
    -animation: spin .7s infinite linear
    -moz-animation: spin .7s infinite linear
    -ms-animation: spin .7s infinite linear
    -webkit-animation: spin .7s infinite linear

  /* followup coupon*/

  .followup-rules
    border: 1px solid $om-gray-400
    border-radius: 4px
    color: $om-gray-700
    font-size: 0.75rem
    padding: 12px
    line-height: 1.5

    .followup-rule-title
      color: $om-gray-800
      font-weight: bold
      margin-bottom: 12px

  .limit-in-unit-input
    width: 100%
    margin-left: 0 !important

    ::v-deep .epic-simple-input
      width: 100%

      select
        width: 100% !important

      .select-arrow
        top: 14px !important
        right: 10px

  .limit-in-input
    ::v-deep .validation-failed-msg
      display: none

  .limit-required
    margin: 0

  .limit-switch
    color: $om-gray-700 !important

  .follow-up-description-list
    margin: 1rem 0
    padding-left: 1.5rem
</style>
