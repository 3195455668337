<template lang="pug">
div
  loading-logo(v-if="loading")
  template(v-else)
    template(v-if="$i18n.locale === 'en'")
      .d-flex.mb-2.align-items-center
        .label-text.mr-2.w-25.font-weight-bold {{ $t('firstName') }}*
        input.form-control.form-control-md.w-50(
          :placeholder="billingPlaceholder.firstName"
          v-model.trim="billing.firstName"
          :class="{ 'is-invalid': $v.billing.firstName.$error }"
          autocomplete="given-name"
        )
        div(v-if="$v.billing.firstName.$dirty")
          .brand-error(v-if="!$v.billing.firstName.required") {{ $t('error.field.required') }}
          .brand-error(v-if="!$v.billing.firstName.minLength") {{ $t('error.field.minLength') }}
      .d-flex.mb-2.align-items-center
        .label-text.mr-2.w-25.font-weight-bold {{ $t('lastName') }}*
        input.form-control.form-control-md.w-50(
          :placeholder="billingPlaceholder.lastName"
          v-model.trim="billing.lastName"
          :class="{ 'is-invalid': $v.billing.lastName.$error }"
          autocomplete="family-name"
        )
        div(v-if="$v.billing.lastName.$dirty")
          .brand-error(v-if="!$v.billing.lastName.required") {{ $t('error.field.required') }}
          .brand-error(v-if="!$v.billing.lastName.minLength") {{ $t('error.field.minLength') }}
    template(v-else)
      .d-flex.mb-2.align-items-center
        .label-text.mr-2.w-25.font-weight-bold {{ $t('lastName') }}*
        input.form-control.form-control-md.w-50(
          :placeholder="billingPlaceholder.lastName"
          v-model.trim="billing.lastName"
          :class="{ 'is-invalid': $v.billing.lastName.$error }"
          autocomplete="family-name"
        )
        div(v-if="$v.billing.lastName.$dirty")
          .brand-error(v-if="!$v.billing.lastName.required") {{ $t('error.field.required') }}
          .brand-error(v-if="!$v.billing.lastName.minLength") {{ $t('error.field.minLength') }}
      .d-flex.mb-2.align-items-center
        .label-text.mr-2.w-25.font-weight-bold {{ $t('firstName') }}*
        input.form-control.form-control-md.w-50(
          :placeholder="billingPlaceholder.firstName"
          v-model.trim="billing.firstName"
          :class="{ 'is-invalid': $v.billing.firstName.$error }"
          autocomplete="given-name"
        )
        div(v-if="$v.billing.firstName.$dirty")
          .brand-error(v-if="!$v.billing.firstName.required") {{ $t('error.field.required') }}
          .brand-error(v-if="!$v.billing.firstName.minLength") {{ $t('error.field.minLength') }}
    .d-flex.mb-2.align-items-center
      .label-text.mr-2.w-25.font-weight-bold {{ $t('billingName') }}{{ billing.euVatNumber.length > 0 ? '*' : '' }}
        br
        span.help {{ $t('billingHelp') }}
      input.form-control.form-control-md.w-50(
        :placeholder="billingPlaceholder.billingName"
        v-model.trim="billing.billingName"
        :class="{ 'is-invalid': $v.billing.billingName.$error }"
        autocomplete="organization"
      )
      div(v-if="$v.billing.billingName.$error")
        .brand-error(v-if="!$v.billing.billingName.required") {{ $t('error.field.required') }}
    .d-flex.mb-2.align-items-center
      .label-text.mr-2.w-25.font-weight-bold {{ $t('address') }}*
      input.form-control.form-control-md.w-50(
        :placeholder="billingPlaceholder.street"
        v-model.trim="billing.address"
        :class="{ 'is-invalid': $v.billing.address.$error }"
        autocomplete="street-address"
      )
      div(v-if="$v.billing.address.$dirty")
        .brand-error(v-if="!$v.billing.address.required") {{ $t('error.field.required') }}
        .brand-error(v-if="!$v.billing.address.minLength") {{ $t('error.field.minLength') }}
    .d-flex.mb-2.align-items-center
      .label-text.mr-2.w-25.font-weight-bold {{ $t('city') }}*
      input.form-control.form-control-md.w-50(
        :placeholder="billingPlaceholder.city"
        v-model.trim="billing.city"
        :class="{ 'is-invalid': $v.billing.city.$error }"
        autocomplete="address-level2"
      )
      div(v-if="$v.billing.city.$dirty")
        .brand-error(v-if="!$v.billing.city.required") {{ $t('error.field.required') }}
        .brand-error(v-if="!$v.billing.city.minLength") {{ $t('error.field.minLength') }}
    .d-flex.mb-2.align-items-center
      .label-text.mr-2.w-25.font-weight-bold {{ $t('postCode') }}*
      input.form-control.form-control-md.w-25(
        :placeholder="billingPlaceholder.pcode"
        v-model.trim="billing.postalCode"
        :class="{ 'is-invalid': $v.billing.postalCode.$error }"
        autocomplete="postal-code"
      )
      div(v-if="$v.billing.postalCode.$dirty")
        .brand-error(v-if="!$v.billing.postalCode.required") {{ $t('error.field.required') }}
        .brand-error(v-if="!$v.billing.postalCode.minLength") {{ $t('error.field.minLength') }}
    .d-flex.mb-2.align-items-center
      .label-text.mr-2.w-25.font-weight-bold {{ $t('country') }}*
      select.form-control.form-control-md.w-25(
        v-model="billing.countryCode"
        autocomplete="country-name"
      )
        option(v-for="option in getCountries" :value="option.code") {{ option.text }}
    .d-flex.mb-2.align-items-center
      .label-text.mr-2.w-25.font-weight-bold {{ $t('euVatNumber') }}{{ isVatNumberRequired ? '*' : '' }}
        template(v-if="!isVatNumberRequired")
          br
          span.help ({{ $t('optional') }})
      input.form-control.form-control-md.w-25(
        :placeholder="billingPlaceholder.euVatNumber"
        v-model.trim="billing.euVatNumber"
        :class="{ 'is-invalid': $v.billing.euVatNumber.$error }"
      )
      div(v-if="$v.billing.euVatNumber.$error")
        .brand-error(v-if="!$v.billing.euVatNumber.required") {{ $t('error.field.euVatRequired') }}
    .d-flex.my-2.align-items-center(v-if="showSaveButton")
      om-button(primary @click.prevent="saveBilling") {{ $t('save') }}
</template>

<script>
  import { getCountryByLocale } from '@/config/countries';
  import { mapGetters } from 'vuex';
  import addressValidation from '@/mixins/address';

  export default {
    name: 'CheckoutBilling',
    mixins: [addressValidation],
    props: {
      loading: { type: Boolean, default: false },
      billing: { type: Object, required: true },
      showSaveButton: { type: Boolean, default: true },
    },
    data() {
      return {
        countries: [
          { text: 'Hungary', value: 97, code: 'HU' },
          { text: 'United States', value: 223, code: 'US' },
        ],
      };
    },
    computed: {
      getCountries() {
        return this.countries;
      },
      billingPlaceholder() {
        return this.$i18n.locale === 'en'
          ? {
              firstName: 'e. g. John',
              lastName: 'e. g. Appleseed',
              city: 'pl. New York',
              street: 'e. g. High street 3',
              pcode: 'e. g. 2457',
              euVatNumber: 'e. g. 123456',
              billingName: 'e. g. Best Company',
            }
          : {
              firstName: 'pl. János',
              lastName: 'pl. Kiss',
              city: 'pl. Budapest',
              street: 'pl. Rövid utca 12.',
              pcode: 'pl. 1016',
              euVatNumber: 'pl. 123456',
              billingName: 'pl. Saját Kft.',
            };
      },
    },
    created() {
      this.$set(this, 'countries', getCountryByLocale(this.$i18n.locale));
    },
    methods: {
      ...mapGetters(['getRegion']),
      saveBilling() {
        this.$v.$touch();

        if (this.$v.$invalid) {
          return;
        }

        const data = { ...this.billing };
        const country = this.getCountryByCode(data.countryCode);

        if (!country) {
          return; // No such country
        }

        data.country = country.value;

        this.$emit('saveBilling', data);
      },
      getCountryByCode(code) {
        for (const index in this.countries) {
          if (this.countries[index].code === code) {
            return this.countries[index];
          }
        }

        return false;
      },
    },
  };
</script>
