<template lang="pug">
.h-100
  .pane
    sidebar-title
    .sidebar-inner-box.pt-0
      sidebar-tab-wrapper(defaultTab="general")
        template(#content)
          sidebar-tab-content#general
            om-dropdown-input(
              label="nrOfProducts"
              property="selectedElement.data.nrOfProducts"
              :items="nrOfProductOptions"
              :i18n="false"
              typeOfComponent="product"
            )
              .sidebar-help-wrapper.mt-2(slot="help")
                .sidebar-help-content {{ $t('productPaneTooltips.notDisplayedIfNoEnoughProducts') }}
            template(
              v-if="isActiveShopify || isActiveShoprenter || isTemplateEditorMode || featureOptimonkRecommender"
            )
              .d-flex.pt-2
                .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0.pr-0 {{ $t('mode') }}
              om-dropdown-input.wide-select-100(
                label=""
                property="selectedElement.data.mode"
                :items="modes"
                typeOfComponent="product"
              )
              template(v-if="productFilterTypes[selectedMode]")
                .d-flex.pt-2
                  .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0.pr-0 {{ $t(`productFilterLabels.${selectedMode}`) }}
                om-dropdown-input.wide-select-100(
                  label=""
                  property="selectedElement.data.productFilter.type"
                  :items="productFilterTypes[selectedMode]"
                  typeOfComponent="product"
                )
                template(v-if="selectedProductFilterType === 'category'")
                  .d-flex.pt-2
                    .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0.pr-0 {{ $t('categoryId') }}
                  .sidebar-input-wrapper.flex-row-baseline
                    multiselect.wide-select-100(
                      v-model="categoryId"
                      :options="categories"
                      label="key"
                      track-by="value"
                      :closeOnSelect="true"
                      :searchable="true"
                      :allow-empty="false"
                      :placeholder="$t('select')"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel=""
                      open-direction="bottom"
                    )
              SwitchableSetting(
                v-if="isActiveShopify && isManual"
                toggleProperty="selectedElement.data.showOutOfStock"
                title="productTexts.title.showOutOfStock"
                :collapsible="false"
              )
                template(slot="tooltip")
                  om-tooltip.ml-2(color="#D1D5DF")
                    span {{ $t('productPaneTooltips.showOutOfStock') }}
                om-simple-input(label="" :property="`selectedElement.data.ctaOutOfStockText`")
            om-switches(
              label="productTexts.title.openInNewTab"
              v-if="selectedElement.data.clickAction !== 'add-to-cart'"
              property="selectedElement.data.cta.openInNewTab"
            )
            SwitchableSetting(
              v-for="item in items"
              :toggleProperty="`selectedElement.data.${item}.active`"
              :key="`selectedElement.data.${item}.settings`"
              :title="`productTexts.title.${item}`"
              :collapsible="false"
            )
            SwitchableSetting(
              toggleProperty="selectedElement.data.cta.active"
              title="button"
              :collapsible="false"
            )
              template(slot="tooltip" v-if="!isStatic")
                om-tooltip.ml-2(color="#D1D5DF")
                  span {{ $t('productPaneTooltips.abscenceOfButton') }}
              .d-flex.pt-2
                .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0.pr-0 {{ $t('text') }}
              om-simple-input(label="" :property="`selectedElement.data.ctaText`")
              template(v-if="!isStatic")
                .d-flex.pt-2
                  .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pt-0.pr-0 {{ $t('clickAction') }}
                om-dropdown-input.wide-select-100(
                  label=""
                  property="selectedElement.data.clickAction"
                  :items="clickActions"
                  typeOfComponent="product"
                  :disabled="selectedMode === 'products-in-the-cart'"
                )
          sidebar-tab-predefined-style(property="selectedElement" component="product")
            template(slot="before")
              custom-theme-style
              sidebar-accordion(:title="$t('display')" opened)
                .editor-row
                  .sidebar-input-wrapper.flex-row-baseline.px-2
                    .col-6(@click="changeLayout('horizontal')")
                      HorizontalSvg(width="100%" height="100%" :class="{ selected: isHorizontal }")
                    .col-6(@click="changeLayout('vertical')")
                      VerticalSvg(width="100%" height="100%" :class="{ selected: isVertical }")
                  align-select(
                    v-if="isVertical"
                    label="align"
                    :property="getPath('alignItems')"
                    :options="aligns.map(({ key }) => key)"
                    :inline="true"
                  )
                  Size(
                    subPath="selectedElement.desktop"
                    typeOfComponent="product"
                    :needHeight="false"
                  )
              sidebar-accordion(
                v-if="selectedElement.data.image.active"
                :title="$t('productTexts.title.image')"
              )
                om-range-input(
                  label="size"
                  property="selectedElement.subElements.image.desktop.height"
                  :min="20"
                  :max="800"
                  :step="1"
                )
                om-margin(
                  subPath="selectedElement.subElements.image.$device.margin"
                  :showAccordion="false"
                  typeOfComponent="product"
                )
                om-padding(
                  subPath="selectedElement.subElements.image.$device.padding"
                  :showAccordion="false"
                  typeOfComponent="product"
                )
                Border(subPath="subElements.image.desktop" typeOfComponent="product")
              sidebar-accordion(
                v-for="item in items.filter((key) => !['button', 'image'].includes(key))"
                v-if="selectedElement.data[item].active"
                :key="`selectedElement.data.${item}.settings`"
                :title="$t(`productTexts.title.${item}`)"
              )
                SubSection(v-if="isStatic" label="font")
                  FontSetting(:subPath="`selectedElement.subElements.${item}Text.desktop`")
                FontSetting(v-else :subPath="`selectedElement.subElements.${item}Text.desktop`")
                om-margin(
                  :subPath="`selectedElement.subElements.${item}.$device.margin`"
                  :showAccordion="false"
                  typeOfComponent="product"
                )
                om-padding(
                  :subPath="`selectedElement.subElements.${item}.$device.padding`"
                  :showAccordion="false"
                  typeOfComponent="product"
                )
              sidebar-accordion(:title="$t('button')")
                SubSection(label="size")
                Size(subPath="selectedElement.subElements.button.desktop" typeOfComponent="button")
                SubSection(label="font")
                  om-dropdown-input(
                    label="textAlign"
                    property="selectedElement.subElements.buttonText.desktop.alignSelf"
                    :items="textAligns"
                    typeOfComponent="product"
                  )
                  FontSetting(subPath="selectedElement.subElements.buttonText.desktop")
                SubSection(label="background")
                  om-color-input(
                    label="fill"
                    property="subElements.button.$device.background.color"
                    typeOfComponent="product"
                    :onlySolid="true"
                  )
                SubSection(label="hover")
                  Hover(subPath="selectedElement.subElements.button.desktop.hover")
                om-padding(
                  subPath="selectedElement.subElements.button.$device.padding"
                  :showAccordion="false"
                  typeOfComponent="product"
                )
                om-margin(
                  subPath="selectedElement.subElements.button.$device.margin"
                  :showAccordion="false"
                  typeOfComponent="product"
                )
                SubSection(label="borderAndShadow")
                  Border(subPath="subElements.button.desktop" typeOfComponent="product")
          advanced-tab(:spacingProps="spacingProps")
    portal(to="root")
      product-catalog
      product-details
</template>
<script>
  import { mapGetters, mapMutations, mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import GET_SHOP_INFO from '@/graphql/GetEcommerceShopInfo.gql';
  import { getCollections } from '@/services/jfAdapter';
  import AlignSelect from '@/editor/components/sidebar/components/AlignSelect.vue';
  import { isOptimonkRecommenderEnabled } from '@/utils/features';

  const pane = 'Product';

  export default {
    components: {
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
      HorizontalSvg: () => import('@/editor/components/svg/product/LayoutHorizontal.vue'),
      VerticalSvg: () => import('@/editor/components/svg/product/LayoutVertical.vue'),
      FontSetting: () => import('@/editor/components/sidebar/components/FontSetting.vue'),
      Border: () => import('@/editor/components/sidebar/components/Border.vue'),
      Hover: () => import('@/editor/components/sidebar/components/Hover.vue'),
      SubSection: () => import('@/editor/components/sidebar/components/SubSection.vue'),
      Size: () => import('@/editor/components/sidebar/components/Size.vue'),
      ProductCatalog: () => import('@/editor/components/modals/ProductCatalog.vue'),
      ProductDetails: () => import('@/editor/components/modals/ProductDetails.vue'),
      SwitchableSetting: () =>
        import('@/editor/components/sidebar/components/SwitchableSettingWrapper.vue'),
      AlignSelect,
    },
    mixins: [itemMixin],
    data: () => ({
      canEditMobile: true,
      display: true,
      isActiveShopify: false,
      isActiveShoprenter: false,
      categories: [],
      nrOfProductOptions: [
        { key: '1', value: 1 },
        { key: '2', value: 2 },
        { key: '3', value: 3 },
      ],
      aligns: [
        { key: 'left', value: 'left' },
        { key: 'center', value: 'center' },
      ],
      textAligns: [
        { key: 'left', value: 'flex-start' },
        { key: 'center', value: 'center' },
        { key: 'right', value: 'flex-end' },
      ],
      items: ['image', 'name', 'sku', 'price', 'oldPrice'],
    }),
    computed: {
      ...mapState(['account', 'selectedElement', 'validationError', 'userInfoMessage', 'campaign']),
      ...mapGetters([
        'shopSettingsByDomain',
        'databaseId',
        'isActiveShopifyDomain',
        'isActiveShoprenterDomain',
        'isTemplateEditorMode',
      ]),
      isEmbeddedV3() {
        return this.campaign.version === 3;
      },
      features() {
        return this.account && this.account.features ? this.account.features : [];
      },
      featureOptimonkRecommender() {
        return isOptimonkRecommenderEnabled(this.features);
      },
      modes() {
        const modes = [{ key: 'productModes.manual', value: 'manual' }];
        if (this.isEmbeddedV3) return modes;

        if (this.isActiveShopify || this.isTemplateEditorMode) {
          modes.push(
            { key: 'productModes.last-visited', value: 'last-visited' },
            { key: 'productModes.most-viewed', value: 'most-viewed' },
            { key: 'productModes.smart-recommendation', value: 'smart-recommendation' },
            { key: 'productModes.products-in-the-cart', value: 'products-in-the-cart' },
          );
        }

        if (this.isActiveShoprenter) {
          modes.push(
            { key: 'productModes.last-visited', value: 'last-visited' },
            { key: 'productModes.most-viewed', value: 'most-viewed' },
            {
              key: 'productModes.smart-recommendation-related',
              value: 'smart-recommendation-related',
            },
            {
              key: 'productModes.smart-recommendation-similar',
              value: 'smart-recommendation-similar',
            },
            {
              key: 'productModes.products-in-the-cart',
              value: 'products-in-the-cart',
            },
          );
        }

        if (this.featureOptimonkRecommender) {
          modes.push({
            key: 'productModes.optimonk-recommender',
            value: 'optimonk-recommender',
          });
        }

        return modes;
      },
      productFilterTypes() {
        const types = {
          'most-viewed': [
            { key: 'productFilters.most-viewed.none', value: 'none' },
            { key: 'productFilters.most-viewed.category', value: 'category' },
            { key: 'productFilters.most-viewed.viewed-category', value: 'viewed-category' },
          ],
        };

        return types;
      },
      isStatic() {
        return this.selectedMode === 'manual' && !this.isActiveShopify && !this.isActiveShoprenter;
      },
      selectedMode() {
        return this.getValueOf('selectedElement.data.mode');
      },
      selectedProductFilterType() {
        return this.getValueOf('selectedElement.data.productFilter.type');
      },
      defaultCtaTexts() {
        return [
          this.$t('productTexts.example.cta.addToCart'),
          this.$t('productTexts.example.cta.redirect'),
          this.$t('productTexts.example.cta.redirectToCart'),
        ];
      },
      isVertical() {
        return this.selectedElement.data.layout === 'vertical';
      },
      isHorizontal() {
        return this.selectedElement.data.layout === 'horizontal';
      },
      validationFailedIndex() {
        if (this.validationError) {
          for (const i in this.selectedElement.data.products) {
            if (this.validationError.property === `selectedElement.data.products[${i}].url`)
              return i;
          }
        }
        return false;
      },
      isManual() {
        return this.selectedMode === 'manual';
      },
      categoryId: {
        get() {
          return this.categories.find(
            ({ value }) => `${value}` === this.selectedElement.data.productFilter.categoryId,
          );
        },
        set({ value }) {
          this.selectedElement.data.productFilter.categoryId = value;
        },
      },
      spacingProps() {
        return {
          typeOfComponent: pane.toLowerCase(),
        };
      },
      clickActions() {
        if (this.selectedMode === 'products-in-the-cart') {
          return [{ key: 'productTexts.clickActions.redirectToCart', value: 'redirect-to-cart' }];
        }
        return [
          { key: 'productTexts.clickActions.addToCart', value: 'add-to-cart' },
          { key: 'productTexts.clickActions.redirectToProduct', value: 'redirect' },
        ];
      },
    },
    watch: {
      selectedMode(newMode) {
        if (newMode === 'manual') {
          this.selectedElement.data.clickAction = this.clickActions[0].value;
          if (this.isStatic) {
            this.selectedElement.data.cta.active = true;
          }
        } else if (newMode === 'products-in-the-cart') {
          this.selectedElement.data.clickAction = 'redirect-to-cart';
          this.selectedElement.data.cta.active = false;
        } else {
          this.selectedElement.data.clickAction = this.clickActions[0].value;
          const storeType = this.selectedElement.data.storeType;
          if (storeType === 'shopify' || storeType === 'shoprenter') {
            this.fetchShopInfo(storeType);
          }

          if (newMode !== 'most-viewed') {
            this.selectedElement.data.categoryId = null;
          }
        }
        this.setExampleProducts();
      },
      selectedProductFilterType(type) {
        if (type === 'none') {
          this.selectedElement.data.productFilter.categoryId = null;
        }
      },
      'selectedElement.data.clickAction': function (action) {
        const isDefaultText = this.defaultCtaTexts.includes(this.selectedElement.data.ctaText);
        if (isDefaultText) {
          if (action === 'add-to-cart') {
            this.selectedElement.data.ctaText = this.$t('productTexts.example.cta.addToCart');
          } else if (action === 'redirect') {
            this.selectedElement.data.ctaText = this.$t('productTexts.example.cta.redirect');
          } else if (action === 'redirect-to-cart') {
            this.selectedElement.data.ctaText = this.$t('productTexts.example.cta.redirectToCart');
          }
        }
      },
      'selectedElement.data.nrOfProducts': function (n, o) {
        if (this.selectedElement.data.mode !== 'manual') {
          this.setExampleProducts();
        }
        if (n < o) {
          const prods = this.getValueOf('selectedElement.data.products');
          this.setValueOf('selectedElement.data.products', prods.slice(0, n));
        }
      },
      validationError() {
        if (this.validationFailedIndex !== false) {
          this.openProductDetailsOnError(this.validationFailedIndex);
        }
      },
    },
    created() {
      this.$bus.$on('handleProductClick', this.handleProductClick);
    },
    beforeDestroy() {
      this.$bus.$off('handleProductClick', this.handleProductClick);
    },
    mounted() {
      if (this.campaign) {
        const { domain } = this.campaign;
        if (domain) {
          this.isActiveShopify = this.isActiveShopifyDomain(domain);
          this.isActiveShoprenter = this.isActiveShoprenterDomain(domain);
          const storeType = this.selectedElement.data.storeType;
          if (storeType) {
            this.fetchShopInfo(storeType);
            this.fetchCategories();
          }
        }
      }
      this.$bus.$emit('checkShopConnection');
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.validationFailedIndex !== false) {
            this.openProductDetailsOnError(this.validationFailedIndex);
          }
        }, 150);
      });
    },
    methods: {
      ...mapMutations(['updateData', 'updateStyle', 'setSelectedProductIndex']),
      changeLayout(direction) {
        this.updateData({
          property: 'selectedElement.data.layout',
          value: direction,
        });
        const align = this.isVertical ? 'center' : 'left';
        this.updateData({
          property: 'selectedElement.data.align',
          value: align,
        });
        this.$bus.$emit('historySave');
      },
      toggle(item) {
        if (this[item].active) this[`${item}Show`] = !this[`${item}Show`];
      },
      async fetchShopInfo(type) {
        const { data } = await this.$apollo.query({
          query: GET_SHOP_INFO,
          variables: { type, domain: this.campaign.domain },
        });

        if (data && data.getShopInfo) {
          const { moneyFormat } = data.getShopInfo;

          if (moneyFormat) {
            this.selectedElement.data.moneyFormat = moneyFormat;
          }
        }
      },
      openProductDetailsOnError(index) {
        this.$nextTick(() => {
          this.openProductDetails(index);
        });
      },
      async fetchCategories() {
        const shop = this.shopSettingsByDomain(this.campaign.domain);

        if (shop) {
          try {
            const shopId = shop.type === 'shoprenter' ? shop.shopname : shop.myshopify_domain;
            const collections = await getCollections(this.databaseId, shop.type, shopId);

            if (collections) {
              const collectionIdMap = new Map(
                collections.map((collection) => [collection.collectionId, collection]),
              );

              const normalizeName = (collection) => {
                const parentId = collection.parent && collection.parent.collectionId;
                if (parentId && parentId !== collection.collectionId) {
                  const parent = collectionIdMap.get(parentId);

                  if (parent) {
                    return `${normalizeName(parent)} > ${collection.title}`;
                  }
                }

                return collection.title;
              };

              this.categories = collections.map((collection) => ({
                key: normalizeName(collection),
                value: `${collection.collectionId}`,
              }));

              return;
            }
          } catch (err) {
            console.error(err);
          }
        }
      },
      handleProductClick(index) {
        if (this.isManual) {
          this.setSelectedProductIndex(index);
          const selectedProducts = this.getValueOf('selectedElement.data.products');
          if (this.isActiveShopify) {
            this.$modal.show('product-catalog', {
              type: 'shopify',
              setProducts: selectedProducts || [],
            });
          } else if (this.isActiveShoprenter) {
            this.$modal.show('product-catalog', {
              type: 'shoprenter',
              setProducts: selectedProducts || [],
            });
          } else {
            this.openProductDetails(index);
          }
        }
      },
      openProductDetails(index) {
        this.setSelectedProductIndex(index);
        this.$modal.show('product-details');
      },
      setExampleProducts() {
        const nrOfProducts = this.selectedElement.data.nrOfProducts;
        const products = [...this.selectedElement.data.products];

        for (let i = 0; i < nrOfProducts; i++) {
          if (!products[i]) {
            products[i] = {
              image: {
                imageId: null,
                imageUrl: null,
              },
              name: this.$t('productTexts.example.name'),
              sku: this.$t('productTexts.example.sku'),
              price: this.$t('productTexts.example.price.new'),
              oldPrice: this.$t('productTexts.example.price.old'),
            };
          }
        }

        this.$set(this.selectedElement.data, 'products', products);
      },
    },
  };
</script>
