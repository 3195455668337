export default {
  props: {
    property: { type: String, required: true },
    component: { type: String, required: true },
    mobile: { type: Boolean, default: false },
  },
  computed: {
    contentRef() {
      return this.$refs.content;
    },
  },
  methods: {
    getId() {
      if (this.contentRef) {
        return this.contentRef.getId();
      }
    },
    show() {
      this.$refs.content.show();
    },
    hide() {
      this.$refs.content.hide();
    },
  },
};
