<template lang="pug">
button(:class="classes" v-bind="$attrs" @click="onClick" v-on="$listeners" :type="type")
  span(v-if="loading")
    lottie(filename="btn_loading")
    span.btn-loading-text
      slot
  span.d-inline-flex.justify-content-center.align-items-center(
    v-else-if="icon || $slots.icon"
    :class="{ 'btn-icon-only-wrap': iconOnly, 'btn-add-icon-wrap': !iconOnly, 'mr-1': !iconOnly }"
  )
    component(v-if="icon" :is="iconComponent" :size="iconSize")
    slot(v-else name="icon")
  span.d-inline-flex.justify-content-center.align-items-center(v-if="!loading && !iconOnly")
    slot
</template>

<script>
  import Lottie from '@/components/Lottie.vue';
  import {
    UilSignAlt,
    UilPlusCircle,
    UilPlayCircle,
    UilStopCircle,
    UilQuestionCircle,
    UilEllipsisV,
    UilAngleLeftB,
    UilAngleRightB,
    UilMultiply,
    UilSetting,
    UilTimes,
    UilArchive,
    UilEditAlt,
    UilTrashAlt,
    UilRedo,
    UilRefresh,
    UilLinkAlt,
    UilDesktop,
    UilMobileAndroid,
    UilUpload,
    UilEnvelopeLock,
    UilEdit,
    UilUserCheck,
    UilBookAlt,
    UilStar,
    UilCheck,
    UilEstate,
    UilVectorSquare,
    UilSync,
    UilAngleDown,
    UilAngleUp,
    UilCommentDots,
    UilBoltAlt,
    UilCloudUpload,
    UilFileDownload,
    UilCopy,
    UilComments,
    UilSearchPlus,
    UilSearchMinus,
    UilEye,
    UilEyeSlash,
    UilEnvelope,
  } from '@iconscout/vue-unicons';
  import { buildClassObject } from './helpers';
  import designSystemMixin from '../mixins/designSystem';
  import iconComponent from '../mixins/iconComponent';

  export default {
    name: 'OmButton',
    components: {
      UilSignAlt,
      UilPlusCircle,
      UilPlayCircle,
      UilStopCircle,
      UilQuestionCircle,
      UilEllipsisV,
      UilAngleLeftB,
      UilAngleRightB,
      UilMultiply,
      UilSetting,
      UilTimes,
      UilArchive,
      UilEditAlt,
      UilTrashAlt,
      UilRedo,
      UilRefresh,
      UilLinkAlt,
      UilDesktop,
      UilMobileAndroid,
      UilUpload,
      UilEnvelopeLock,
      Lottie,
      UilEdit,
      UilUserCheck,
      UilBookAlt,
      UilStar,
      UilCheck,
      UilEstate,
      UilVectorSquare,
      UilSync,
      UilAngleDown,
      UilCommentDots,
      UilBoltAlt,
      UilCloudUpload,
      UilFileDownload,
      UilAngleUp,
      UilCopy,
      UilComments,
      UilSearchPlus,
      UilSearchMinus,
      UilEye,
      UilEyeSlash,
      UilEnvelope,
    },
    mixins: [designSystemMixin, iconComponent],
    props: {
      to: {
        type: [String, Object],
        default: () => null,
      },
      primary: {
        type: Boolean,
        default: false,
      },
      secondary: {
        type: Boolean,
        default: false,
      },
      ghost: {
        type: Boolean,
        default: false,
      },
      iconOnly: {
        type: Boolean,
        default: false,
      },
      pressed: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      block: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      selected: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'button',
        options: ['button', 'submit', 'reset'],
        validator: (value) => {
          return ['button', 'submit', 'reset'].includes(value.toLowerCase());
        },
      },
    },

    computed: {
      classes() {
        const btnClasses = buildClassObject(this);
        return {
          ...btnClasses,
          disabled: this.disabled,
          'design-system': this.designSystem,
          selected: this.selected,
          pressed: this.pressed,
        };
      },
    },
    methods: {
      onClick() {
        if (this.to) {
          this.$router.push(this.to);
        }
        this.removeFocus();
      },
      removeFocus() {
        this.$el.blur();
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '../../../sass/variables/_colors.sass'

  $border-radius: 4px
  $om-orange: #ed5a29
  $om-ds-gray: #B9BEC6
  $om-gray-500: $om-ds-gray
  $om-secondary: #B9BEC6
  $om-auxiliary-color: #FFE0CC
  $om-auxiliary-dark-color: #8B5043

  /* primary color */
  $primary-color: $om-orange
  $primary-light-color: lighten($primary-color, 10%)
  $primary-active-color: darken($primary-color, 10%)
  $primary-lighten-color: lighten($primary-color, 20%)
  $primary-ultra-light-color: lighten($primary-color, 35%)

  /* secondary color */
  $secondary-color: $om-secondary
  $secondary-hover-color: lighten($om-secondary, 20%)
  $secondary-light-color: lighten($om-secondary, 15%)
  $secondary-dark-color: darken($om-secondary, 15%)
  $secondary-ultradark-color: darken($om-secondary, 40%)

  /* auxiliary color */
  $auxiliary-color: $om-auxiliary-color
  $auxiliary-light-color: lighten($auxiliary-color, 5%)
  $auxiliary-dark-color: darken($auxiliary-color, 5%)
  $auxiliary-ultra-dark-color: $om-auxiliary-dark-color

  @mixin btnBoxShadow($shadow-color)
    box-shadow: 0 3px 10px rgba($shadow-color, 0.25)
  @mixin btnBoxShadowFocus($shadow-color)
    box-shadow: 0 0 0 0.2rem rgba($shadow-color, 0.25)
  @mixin btnBoxShadowNoneImportant()
    box-shadow: none !important
  /* base btn style */
  .btn
    display: flex
    align-items: center
    justify-content: center
    padding: .4375rem 0.9375rem
    font-size: 14px
    line-height: 1.75
    font-weight: 500
    border-radius: $border-radius
    position: relative
    min-width: 5rem
    &-sm
      min-width: 3.75rem
    &-lg
      min-width: 6.25rem
    &:focus,
    &.focus
      @include btnBoxShadowFocus($om-secondary)
    &:hover
      text-decoration: underline
    &.btn-add-icon
      padding-left: 0.6875rem
      padding-right: 0.6875rem
    &.btn-icon-only
      padding-left: 0.4375rem
      padding-right: 0.4375rem
      min-width: auto
  /* primary */
  .btn-primary
    color: white
    background-color: $primary-color
    border-color: $primary-color
    @include btnBoxShadow($primary-color)
    &:hover
      text-decoration: none
      color: white
      background-color: $primary-light-color
      border-color: $primary-light-color
      @include btnBoxShadow($primary-color)
    &:focus
      color: white
      background-color: $primary-light-color
      border-color: $primary-light-color
      @include btnBoxShadowFocus($primary-color)
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active
      color: white
      background-color: $primary-active-color
      border-color: $primary-active-color
      @include btnBoxShadowNoneImportant()
    &.disabled,
    &:disabled
      color: $secondary-dark-color!important
      background-color: $secondary-light-color
      border-color: $secondary-light-color
      display: flex !important
      pointer-events: none
      opacity: 1
      box-shadow: none
  /* secondary */
  .btn-secondary
    color: $secondary-ultradark-color
    background-color: white
    border-color: $secondary-light-color
    box-shadow: none
    &:hover
      text-decoration: none
      color: $secondary-ultradark-color
      background-color: $secondary-hover-color
      border-color: $secondary-light-color
    &:focus
      color: $secondary-ultradark-color
      background-color: $secondary-hover-color
      border-color: $secondary-light-color
      @include btnBoxShadowFocus($om-secondary)
    &.active,
    &:active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active
      color: $secondary-ultradark-color
      background-color: $secondary-light-color
      border-color: $secondary-light-color
      @include btnBoxShadowNoneImportant()
    &.disabled,
    &:disabled
      color: $secondary-color!important
      background-color: white
      border-color: $secondary-light-color
      display: flex !important
      pointer-events: none
      opacity: 1
  // ghost
  .btn-link
    color: $secondary-ultradark-color
    background-color: transparent
    border-color: transparent
    box-shadow: none
    &:hover
      text-decoration: none
      color: $secondary-ultradark-color
      background-color: $secondary-hover-color
    &:focus
      text-decoration: none
      color: $secondary-ultradark-color
      background-color: $secondary-hover-color
      border-color: $secondary-light-color
      @include btnBoxShadowFocus($om-secondary)
    &:active,
    &.pressed
      color: $secondary-ultradark-color
      background-color: $secondary-light-color
      border-color: $secondary-light-color
      @include btnBoxShadowNoneImportant()
    &.disabled,
    &:disabled
      color: $om-gray-500
      background-color: transparent
      border-color: transparent
      display: flex !important
      opacity: 1
    &.selected
      background-color: $om-orange-500-10
      color: $om-orange-500
      &:focus
        box-shadow: none
    &.btn-primary
      color: $primary-color
      background-color: transparent
      box-shadow: none
      &:hover
        text-decoration: none
        color: $primary-color
        background-color: $auxiliary-color
        border-color: $auxiliary-color
        @include btnBoxShadowNoneImportant()
      &:focus
        text-decoration: none
        color: $primary-color
        background-color: $auxiliary-color
        border-color: $auxiliary-color
        @include btnBoxShadowFocus($primary-color)
      &.active,
      &:active,
      &:not(:disabled):not(.disabled).active,
      &:not(:disabled):not(.disabled):active
        color: $primary-color
        background-color: $auxiliary-dark-color
        border-color: $auxiliary-dark-color
        @include btnBoxShadowNoneImportant()
      &.disabled,
      &:disabled
        color: $primary-ultra-light-color !important
        background-color: transparent
        border-color: transparent
        display: flex !important
        opacity: 1
    &.btn-add-icon
      &.disabled,
      &:disabled
        color: $secondary-color
        background-color: transparent
        border-color: transparent
        display: flex !important
        pointer-events: none
        opacity: 1
        box-shadow: none

  .btn-group-sm>.btn,
  .btn-sm
    font-size: 14px
    padding: 0.1875rem 0.6875rem
    &.btn-add-icon
      padding-left: 0.5625rem
      padding-right: 0.5625rem
    &.btn-icon-only
      padding-left: 0.1875rem
      padding-right: 0.1875rem
      min-width: auto

  .btn-group-lg>.btn,
  .btn-lg
    font-size: 16px
    padding: 0.6875rem 1.1875rem
    &.btn-add-icon
      padding-left: 1.125rem
      padding-right: 1.125rem
    &.btn-icon-only
      padding-left: 0.6875rem
      padding-right: 0.6875rem
      min-width: auto

  .btn-block
    flex: 0 0 100%
    max-width: 100%
  .btn-loading
    pointer-events: none
    &-text
      display: inherit
      opacity: 0
      height: 0
      width: 0
    svg path
      stroke: currentColor !important
    span > div
      width: 1.75em !important
      height: 1.75em !important
    &:not(.btn-sm):not(.btn-lg)
      span > div
        max-width: 24px
        max-height: 24px

  .ui-svg-inline
    font-size: 1em
</style>
<style lang="sass">
  .btn-loading
    svg path
      stroke: currentColor !important
</style>
